import React, { useState } from 'react';
import './ManageAddress.css';
import { Card } from 'react-bootstrap';
import { Button, notification } from 'antd';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import AddNewAddress from '../AddNewAddress/AddNewAddress';
import { useEffect } from 'react';
import { deleteAddress, getAddress, updateAddress, defaultAddress } from '../../Services/Api/Api';
import EditNewAddress from '../EditNewAddress/EditNewAdress';
import { useTranslation } from "react-i18next";



const ManageAddress = ({activeStep,setActiveStep}) => {
  
    // const [activeStep, setActiveStep] = useState(0);
    const [addressData, setAddressData] = useState([]);
    const [addressEditData, setAddressEditData] = useState([]);

    useEffect(() => {
        getData();
    }, [AddNewAddress]);

    const getData = async () => {
        const result = await getAddress();
        const { data, status, error } = result || {};
        if (status == 200) {
            setAddressData(data || []);
        } else {
            console.log(error);
        }
    };
    const handleDefault = async (id, isDefault) => {
        // alert(isDefault)
        if (isDefault == false) {
           
            const result = await defaultAddress(id);
            const { data, status, error } = result || {};
            if (status === 200) {
                // notification.success({ message: data?.message });
                getData();
            } else {
                notification.error({ message: error });
            }
        }
        // else{
        //     notification.error({message:"You cannot do this"})
        // }
    };
    
    // Inside renderStepContent()
    
    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleDelete = async (id) => {
        const result = await deleteAddress(id);
        const { data, status, error } = result || {};
        if (status == 200) {
            notification.success({ message: data?.message });
            getData();
        } else {
            notification.error({ message: error });
        }
    };

    const handleEdit = async (id) => {
        const result = await updateAddress(id); // Update address with the provided id
        const { status, data, error } = result || {};
        if (status === 200) {
        setAddressEditData(data)
            handleStep(2)(); // Change step to 1
        } else {
            notification.error({ message: error });
        }
    };

    const { t, i18n } = useTranslation();

    const renderStepContent = () => {
        switch (activeStep) {
            case 0:
                return (
                    <div className='manage-address'>
                        <h4>{t('ManageAddressHeading')}</h4>
                        <div className='row manage-row'>
                            {addressData.map((address) => (
                                <div key={address._id} className='col-md-6 col-sm-12'>
                                    <Card className='manage-col'>
                                        <div className='d-flex justify-content-between align-items-center card-content'>
                                            <h6>{address.address_title}</h6>
                                            <div className='d-flex address-icon'>
                                                <img onClick={() => handleEdit(address._id)} src={'assets/images/icons/Edit_fill.png'} alt="Edit" />
                                                <img onClick={() => handleDelete(address._id)} src={'assets/images/icons/del.png'} alt="Delete" />
                                            </div>
                                        </div>
                                        <div className='card-address'>
                                            <p>{`${address.address_line1}, ${address.address_line2}, ${address.area}, ${address.state}`}</p>
                                            <FormGroup>
                                                <FormControlLabel
                                                    onClick={() => handleDefault(address._id, address.default)} // Pass isDefault value
                                                    className='card-address-default'
                                                    control={<Checkbox 
                                                        checked={address?.default}
                                                           disabled={address.default?true:false} />}
                                                    label="Set as Default"
                                                />
                                            </FormGroup>
                                        </div>
                                    </Card>
                                </div>
                            ))}
                        </div>
                        <Button className='profile-btn' onClick={handleStep(1)}>{t('AddAddressButton')}</Button>
                    </div>
                );
            case 1:
                return <AddNewAddress handleStep={handleStep} getData={getData} />;
            case 2:
                return <EditNewAddress handleStep={handleStep} addressEditData={addressEditData} getData={getData} />;
            default:
                return null;
        }
    };
    

    return <div>{renderStepContent()}</div>;
}

export default ManageAddress;
