import React, { useEffect, useState } from "react";
import ProductCard from "../../Components/ProductCard/ProductCard";
import BreadCrumbs from "../../Components/BreadCrumbs/BreadCrumbs";
import { Select, Checkbox, InputNumber, Slider } from "antd";
import Button from "@mui/material/Button";
import "./AllProducts.css";
import CircularProgress from "@mui/material/CircularProgress";
import { getAllCategory, getBrands, getProducts } from "../../Services/Api/Api";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const AllProducts = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { category, search } = location?.state || {};
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [rangeValue, setRangeValue] = useState([0, 10000]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [brands, setBrands] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState( category != null ? [category] : [] );
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [products, setProducts] = useState([]);
  const [productsLength, setProductsLength] = useState(0);
  const [sortBy, setSortBy] = useState("");
  const [baseURL, setBaseURL] = useState("");
  const [limit, setLimit] = useState(9);
  const [offset, setOffset] = useState(0);
  const [searchItem, setSearch] = useState(search != null ? search : "");
  const [sliderTimeout, setSliderTimeout] = useState(null);

  // console.log(selectedCategories);
  
  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  useEffect(() => {
    if (search || search == "") {
      setSearch(search);
      setProducts([]);
      setOffset(0);
    }
  }, [search, location]);

  useEffect(() => {
    if (category && category != null && categoriesData?.length > 0) {
      setSelectedCategories([category]);
      setProducts([]);
      setOffset(0);
    }
  }, [category, location]);

  useEffect(() => {
    getProductsData();
  }, [
    rangeValue,
    selectedCategories,
    selectedBrands,
    offset,
    sortBy,
    searchItem,
  ]);

  useEffect(() => {
    getBrandsData();
    getCategoriesData();
  }, []);

  // Get Categories Data
  const getCategoriesData = async () => {
    const result = await getAllCategory();
    const { data, status } = result || {};
    if (status === 200) {
      setCategoriesData(data?.categories);
    } else {
      console.log("Internal server error");
    }
  };

  // Get Brands Data
  const getBrandsData = async () => {
    const result = await getBrands();
    const { data, status } = result || {};
    if (status === 200) {
      setBrands(data);
    } else {
      console.log("Internal server error");
    }
  };

  // Get Products Data
  const getProductsData = async () => {
    try {
      setLoading(true);
      const filters = {
        keyword: searchItem,
        brands: selectedBrands,
        categories: selectedCategories,
        // featured_products: true,
        // promoted_products: true,
        sort_by: sortBy,
        pricerange: { min_price: rangeValue[0], max_price: rangeValue[1] },
      };
      const result = await getProducts(filters, limit, offset);
      const { data, status } = result || {};
      if (status === 200) {
        const newProducts = data.products.filter(
          (product) =>
            !products.some((prevProduct) => prevProduct._id === product._id)
        );
        setProducts((prev) => [...prev, ...newProducts]);
        // setProducts((prev) => [...prev, ...data?.products]);
        setProductsLength(data?.products?.length);
        setBaseURL(data?.base_url);
        setLoading(false);
      } else {
        setLoading(false);
        console.log("Internal server error");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching products:", error);
    }
  };

  // Sorting Options
  const sortOptions = [
    {
      value: "",
      label: t("ProductPrice")+": "+t("ProductSortBy"),
    },
    {
      value: "price_low_to_high",
      // label: "Price: Low to High",
      label: t("ProductPrice")+": "+t("ProducLowtoHigh"),

    },
    {
      value: "price_high_to_low",
      // label: "Price: High to Low",
      label: t("ProductPrice")+": "+t("ProducHightoLow"),
    },
  ];

  // Handle Sorting Filter
  const handleSortChange = (value) => {
    setProducts([]);
    setOffset(0);
    setSortBy(value);
  };

  // Handle Categories Filter
  const handleCategoryChange = (category) => {
    setProducts([]);
    setOffset(0);
    setSelectedCategories((prevCategories) => {
      if (prevCategories.includes(category)) {
        return prevCategories.filter((cat) => cat !== category);
      } else {
        return [...prevCategories, category];
      }
    });
  };

  // Handle Brands Filter
  const handleBrandChange = (brand) => {
    setProducts([]);
    setOffset(0);
    setSelectedBrands((prevBrands) => {
      if (prevBrands.includes(brand)) {
        return prevBrands.filter((b) => b !== brand);
      } else {
        return [...prevBrands, brand];
      }
    });
  };

  // Handle Slider Filter
  const handleSliderChange = (value) => {
    clearTimeout(sliderTimeout);
    const timeout = setTimeout(() => {
      setSliderTimeout(null);
      setProducts([]);
      setOffset(0);
      setRangeValue(value);
    }, 100);
    setSliderTimeout(timeout);
  };

  // Handle Slider Input Range Values
  const handleInputChange = (index, value) => {
    const newRangeValue = [...rangeValue];
    newRangeValue[index] = value;
    setRangeValue(newRangeValue);
  };

  // Handle Load More
  const handleLoadMore = () => {
    setOffset((prev) => prev + limit);
  };

  // Handle Reset Filters
  const handleResetFilters = () => {
    navigate("/products");
    setProducts([]);
    setSearch("");
    setOffset(0);
    setSelectedCategories([]);
    setSelectedBrands([]);
    setRangeValue([0, 10000]);
    setSortBy("");
  };

  return (
    <div className="all-products">
      <BreadCrumbs currentPage={t("BreadCrumpProducts")} />
      <div className="container custom-container">
        <div className="col-wrapper">
          <div className="col-left">
            {/* Categories Filter */}
            <div className="filter-card">
              <h3 className="heading">{t("ProductCategoriesHeading")}</h3>
              {categoriesData?.map((item) => (
                <Checkbox
                  key={item?._id}
                  onChange={() => handleCategoryChange(item?._id)}
                  checked={selectedCategories.includes(item?._id)}
                >
                  {i18n?.language === "en" ? item?.name?.en : item?.name?.ar}
                </Checkbox>
              ))}
            </div>
            {/* Brands Filter */}
            <div className="filter-card">
              <h3 className="heading">{t("ProductBrandsHeading")}</h3>
              {brands?.map((item) => (
                <Checkbox
                  key={item?._id}
                  onChange={() => handleBrandChange(item?._id)}
                  checked={selectedBrands.includes(item?._id)}
                >
                  {i18n?.language === "en" ? item?.name?.en : item?.name?.ar}
                </Checkbox>
              ))}
            </div>
            {/* Slider Range Filter */}
            <div className="filter-card">
              <h3 className="heading">{t("ProductPriceHeading")}</h3>
              <Slider
                range
                value={rangeValue}
                onChange={handleSliderChange}
                min={0}
                max={10000}
              />
              <div className="slider-numbers">
                <InputNumber
                  prefix="AED"
                  min={0}
                  max={rangeValue[1]}
                  value={rangeValue[0]}
                  onChange={(value) => handleInputChange(0, value)}
                />
                <InputNumber
                  prefix="AED"
                  min={rangeValue[0]}
                  max={10000}
                  value={rangeValue[1]}
                  onChange={(value) => handleInputChange(1, value)}
                />
              </div>
            </div>
          </div>

          <div className="col-right">
            {/* Sort By Price Filter */}
            <div className="sort-header">
              <div className="sort-msg">{t("ProductShowing")} {products?.length} {t("ProductResults")}</div>
              {/* <div className="sort-msg">Showing 1–12 of 46</div> */}
              <Select
                placeholder="Sort By"
                onChange={handleSortChange}
                options={sortOptions}
                value={sortBy}
              />
            </div>

            {/* Clear Filter */}
            <div className="d-flex justify-content-end mb-4 clearBtn">
              <Button
                variant="outlined"
                size="small"
                onClick={handleResetFilters}
              >
                {t("ProductClearFilter")}
              </Button>
            </div>

            {/* Products Cards */}
            <div className="row">
              {products?.map((itemData, index) => (
                <div key={index} className="col-md-3 col-sm-6">
                  <ProductCard
                    itemData={itemData}
                    baseURL={baseURL}
                    setData={setProducts}
                  />
                </div>
              ))}
            </div>

            {/* No Products Found */}
            {productsLength == 0 && products.length == 0 && !loading && (
              <div className="d-flex justify-center mt-5 not-found">
                {t("NoItemFound")}
              </div>
            )}

            {/* Loading and Load More */}
            {loading ? (
              <div className="d-flex justify-center mt-5">
                <CircularProgress
                  sx={{ color: "#0DC342" }}
                  size={28}
                  thickness={5}
                />
              </div>
            ) : (
              <div className="d-flex justify-center mt-5">
                {productsLength >= limit && (
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => handleLoadMore()}
                    className="load-more"
                  >
                    {t("LoadMore")}
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllProducts;