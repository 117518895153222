import React, { useEffect, useState } from "react";
import HeaderTop from "./HeaderTop";
import Button from "@mui/material/Button";
import { Link, useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import "./Header.css";
import { Dropdown } from "antd";
import { getAllCategory } from "../../Services/Api/Api";
import { useScreenWidth } from "../../Services/Utils/Utils";
import { useTranslation } from "react-i18next";
import HeaderMobile from "./HeaderMobile";

function Header() {
  const { t, i18n } = useTranslation();
  const [categoriesData, setCategoriesData] = useState([]);
  const [baseURL, setBaseUrl] = useState("");
  const screenWidth = useScreenWidth();
  const location = useLocation();
  const { search } = location?.state || {};

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await getAllCategory();
    const { data, status, error } = result || {};
    if (status === 200) {
      setBaseUrl(data?.base_url);
      setCategoriesData(data?.categories);
    } else {
      console.log(error);
    }
  };

  // Filter the first 9 categories and create menu items
  const menuItems = categoriesData.slice(0, 9).map((category) => ({
    key: category._id,
    label: (
      <Link to="/products" state={{ category: category?._id, search: search }}>
        {i18n?.language === "en" ? category?.name?.en : category?.name?.ar}
      </Link>
    ),
    icon: (
      <span className="icon">
        <img src={baseURL + category?.icon} alt="image" />
      </span>
    ),
  }));

  // Add "Others" category
  const otherCategory = {
    key: "other",
    label: <Link to="/products">{t("HeaderBrowseOthers")}</Link>,
    icon: (
      <span className="icon">
        <img src="/assets/images/others.png" alt="Others" />
      </span>
    ),
  };

  // Add "Others" category to menu items if there are more than 9 categories
  if (categoriesData.length > 9) {
    menuItems.push(otherCategory);
  }

  return (
    <div className={i18n.language != "ar" ? "header" : "header header-ar"}>
      <HeaderTop />
      <div className="header-main">
        <div className="container">
          <div className="header-wrapper">
            <div className="head-menu">
              <Dropdown
                menu={{
                  items: menuItems,
                }}
                overlayClassName="browse-categories"
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Button variant="outlined" startIcon={<MenuIcon />}>
                    {t("HeaderBrowseCategories")}
                  </Button>
                </a>
              </Dropdown>
              {screenWidth <= 1024 ? (
                <HeaderMobile />
              ) : (
                <ul>
                  <li>
                    <Link to="/">{t("HeaderHome")}</Link>
                  </li>
                  {/* <li>
                  <Link to="/products">{t("HeaderBrands")}</Link>
                </li> */}
                  <li>
                    <Link to="/products">{t("HeaderProducts")}</Link>
                  </li>
                  <li>
                    <Link to="/about">{t("HeaderAbout")}</Link>
                  </li>
                  <li>
                    <Link to="/contact-us">{t("HeaderContact")}</Link>
                  </li>
                </ul>
              )}
            </div>
            <div className="head-support">
              <img
                className="headphones-fill-icon"
                loading="lazy"
                src="/assets/images/Headphones.png"
                alt="Headphones"
              />
              <div className="text">
                <Link to="tel:+123 456 7890">
                  <b className="support-label">+123 456 7890</b>
                </Link>
                <div className="support-desc">{t("HeaderSupportCenter")}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
