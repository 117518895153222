import React from "react";
import "./Reviews.css";
import { Rate,Button } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import { getReviews } from "../../Services/Api/Api";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../Services/Utils/Utils";

const Reviews = () => {
  const { t, i18n } = useTranslation();
  const [reviewsData, setReviewsData] = useState([]);
  const [baseUrl, setBaseUrl] = useState("");
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(5)
  const [allDataLoaded, setAllDataLoaded] = useState(false);

  useEffect(() => {
    getData();
  }, [offset]);

  const getData = async () => {
    const result = await getReviews(limit,offset);
    const { data, status, error } = result || {};
    if (status == 200) {
      // console.log(data);
      // setReviewsData(data?.reviews);
      setReviewsData(prevTableData => [...prevTableData, ...data?.reviews]);
      setBaseUrl(data?.base_url);
      if (data?.reviews.length === 0) {
        setAllDataLoaded(true);
      }
    } else {
      console.log(error);
    }
  };
  const handleLoadMore = () => {
    setOffset((prevLimit) => prevLimit + 5);
  };

  return (
    <div className="review">
      <h4>{t("MyReviewHeading")}</h4>
      {reviewsData?.map((review) => (
        <div key={review?._id} className="row custom-row">
          <div className="left">
            <div className="imageBox">
              <img
                src={`${baseUrl}${review?.product_details?.images[0]}`}
                alt="review"
              />
            </div>
          </div>
          <div className="right">
            <div className="review-content">
              <h5>
                {i18n?.language === "en"
                  ? review?.product_details?.title?.en
                  : review?.product_details?.title?.ar}
              </h5>
              <h6>{formatDate(review?.t)}</h6>
            </div>
            <Rate disabled defaultValue={review?.rating} />
            <p className="review-para">{review?.description}</p>
          </div>
        </div>
        
      ))}
      {!allDataLoaded  ? (
          <Button className="d-flex m-auto " onClick={handleLoadMore}>
            {t("LoadMore")}
          </Button>
        ) : null}
    </div>
  );
};

export default Reviews;
