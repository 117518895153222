import React, { useEffect, useState } from "react";
import "./PromotionBanner.css";
import { getBanner } from "../../../Services/Api/Api";

function PromotionBanner() {
  const [data, setData] = useState([]);
  const [baseURL, setBaseUrl] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await getBanner();
    const { data, status, error } = result || {};
    if (status === 200) {
      setBaseUrl(data?.base_url);
      const sortedBannerData = data?.banners.sort(
        (a, b) => a.priority - b.priority
      );
      setData(sortedBannerData || []);
    } else {
        console.log("Internal server error");
    }
  };

  return (
    <div className="promotion">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="promotion-wrapper">
              {data?.map((banner) => (
                <div key={banner._id} className="promotionBox">
                  <img
                    src={baseURL + banner.img}
                    alt={`Banner ${banner._id}`}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PromotionBanner;
