import React from 'react'
import './Dashboard.css'
import { Card } from 'react-bootstrap'
import DashboardTable from '../DashboardTable/DashboardTable';
import DashboardCard from '../DashboardCard/DashboardCard';
import { getOrders } from '../../Services/Api/Api';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from "react-i18next";
import MyOrderDetails from '../MyOrders/MyOrderDetails/MyOrderDetails';

const Dashboard = ({ userProfileData }) => {

  const { t, i18n } = useTranslation();
  const [tableData, setTableData] = useState([])
  const [allDataLoaded, setAllDataLoaded] = useState(false);
  const [activeStep, setActiveStep] = useState(0)
  const [orderDetailData, setOrderDetailData] = useState([]);
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(5)

  const cardData = [
    { title: t('DashboardSubHeading1'), value: 0, image: 'assets/images/cardcircle.png' },
    { title: t('DashboardSubHeading2'), value: userProfileData ?.loyalty_points ?.earned, image: 'assets/images/cardstardollar.png' },
    { title: t('DashboardSubHeading3'), value: userProfileData ?.loyalty_points ?.avaiable, image: 'assets/images/cardstar.png' }
  ];

  useEffect(() => {
    getData('current');
  }, [offset]);

  const getData = async (statusType) => {
    const result = await getOrders(statusType, limit, offset);
    const { data, status, error } = result || {};
    if (status === 200) {

      // setTableData(data ?.orders);
      setTableData(prevTableData => [...prevTableData, ...data?.orders]);
      setAllDataLoaded(false);
      if (data && data ?.orders.length < limit) {
        setAllDataLoaded(true);
      }
    } else {
      console.log(error);
    }
  };

  return (
    <div className='dashboard'>
      {activeStep === 0 ? (
        <div>
          <h4>{t('DashboardHeading')}</h4>
          <DashboardCard data={cardData} />
          <DashboardTable data={tableData} tableheading={t('DashboardRecentHeading')} setLimit={setLimit} setOffset={setOffset} getData={getData} allDataLoaded={allDataLoaded} setActiveStep={setActiveStep} setOrderDetailData={setOrderDetailData} />
        </div>
      ) : <MyOrderDetails setActiveStep={setActiveStep} orderDetailData={orderDetailData} dashboard={true} />}
    </div>
  )
}

export default Dashboard

