import React, { useState } from "react";
import { Input, Button, Modal, Rate, Flex, notification } from "antd";
import "./ReviewModal.css";
import { useTranslation } from "react-i18next";
import { postReview } from "../../Services/Api/Api";
import { errMess } from "../../Services/Utils/Utils";

function ReviewModal({ visible, setVisible, data: initialData }) {
  const [rating, setRating] = useState(0);
  const [description, setDescription] = useState("");
  const onCancel = () => {
    setVisible(false);
    setRating(0);
    setDescription("");
  };

  const { t } = useTranslation();

  const submitHandler = async () => {
    if(!description) return
    const formData = {
      product_id: initialData?.productId,
      order_id: initialData?.orderId,
      rating,
      description,
    };
    const response = await postReview(formData);
    const { data, status, error } = response || {};
    if (status === 200) {
      setRating(false);
      setDescription("");
      setVisible(false);
      notification.success({ message: data?.message });
    } else {
      notification.warning({ message: errMess(error) });
    }
  };
  return (
    <Modal
      title={t("ReviewModalHeading")}
      open={visible}
      onCancel={onCancel}
      centered
      className="review-modal"
      footer={[
        <Button key="verify" onClick={submitHandler}>
          {t("ReviewModalButton")}
        </Button>,
      ]}
    >
      <Flex justify="center">
        <Rate
          allowHalf
          allowClear={false}
          value={rating}
          onChange={(e) => setRating(e)}
        />
      </Flex>
      <Input.TextArea
        rows={5}
        placeholder={t("CancelRequestTextareaPH")}
        maxLength={150}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
    </Modal>
  );
}

export default ReviewModal;
