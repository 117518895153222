import React from "react";
import "./ResetPassword.css";
import { Form, Input, Button, notification } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import BreadCrumbs from "../../../Components/BreadCrumbs/BreadCrumbs";
import { useNavigate, useParams } from "react-router-dom";
import { resetPassword } from "../../../Services/Api/Api";
import { validateConfirmPassword, validateTextField, errMess, } from "../../../Services/Utils/Utils";
import { useTranslation } from "react-i18next";

const ResetPassword = () => {
  let { token } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();

  // Handle Submit
  const onFinish = async (values) => {
    const result = await resetPassword(values, token);
    const { data, error, status } = result || {};
    if (status === 200) {
      notification.success({ message: data?.message });
      window.history.replaceState(null, null, "/login");
      navigate("/login");
    } else {
      notification.error({ message: errMess(error) });
    }
  };

  return (
    <div>
      <BreadCrumbs currentPage={t("BreadCrumpResetPass")} />
      <div className="reset">
        <Form form={form} className="reset-form" onFinish={onFinish}>
          <div className="login-heading">
            <h3>Reset Password</h3>
          </div>

          <div className="reset-password-heading">
            <p>Set new password</p>
          </div>

          <div className="input-fields">
            <Form.Item
              name="email"
              rules={[{ type: "email", validator: validateTextField }]}
            >
              <Input
                type="email"
                placeholder={t("LoginEmailPlaceholder")}
                prefix={<img src={"/assets/images/email.png"} alt="Email" />}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[{ min: 8, validator: validateTextField }]}
            >
              <Input.Password
                placeholder={t("SignUpPasswordPlaceholder")}
                type="password"
                prefix={
                  <img src={"/assets/images/passwordIcon.png"} alt="Password" />
                }
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>

            <Form.Item
              name="confirm_password"
              rules={[
                {
                  validator: validateConfirmPassword(form),
                },
              ]}
            >
              <Input.Password
                placeholder={t("SignUpConfirmPasswordPlaceholder")}
                type="password"
                prefix={
                  <img src={"/assets/images/passwordIcon.png"} alt="Password" />
                }
              />
            </Form.Item>
          </div>

          <div className="reset-btn">
            <Button htmlType="submit">Reset</Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ResetPassword;