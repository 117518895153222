import React, { useState, useEffect } from "react";
import MainSlider from "../../Components/Home/MainSlider/MainSlider";
import HomeCategories from "../../Components/Home/HomeCategories/HomeCategories";
import HomePromoted from "../../Components/Home/HomePromoted/HomePromoted";
import PromotionBanner from "../../Components/Home/PromotionBanner/PromotionBanner";
import HomeOffers from "../../Components/Home/HomeOffers/HomeOffers";
import HomeLatest from "../../Components/Home/HomeLatest/HomeLatest";
import HomeDownload from "../../Components/Home/HomeDownload/HomeDownload";
import FeaturedCard from "../../Components/FeaturedCard/FeaturedCard";
import { Modal, Button, notification, message } from "antd";
import PhoneVerificationModal from "../../Components/PhoneVerificationModal/PhoneVerificationModal";
import { otpVerify, phoneUser } from "../../Services/Api/Api";
import { useLocation } from "react-router-dom";
import OtpInput from "react-otp-input";
import OTPVerificationModal from "../../Components/OTPVerificationModal/OTPVerificationModal";

function Home() {
  const location = useLocation();
  const userData = location.state?.userData;
  const [otp, setOtp] = useState("");
  const [showPhoneVerificationModal, setShowPhoneVerificationModal] = useState(false);
  const [openOtpModal, setOpenOtpModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [resendDisabled, setResendDisabled] = useState(true);
  const [secondsRemaining, setSecondsRemaining] = useState(40);

  const handlePhoneVerificationSubmit = async () => {
    const result = await phoneUser(phoneNumber);
    const { status, error, data } = result || {};
    if (status === 200) {
      notification.success({ message: data?.message });
      setOpenOtpModal(true);
    } else {
      notification.error({ message: error.en ? error.en : error?.ar });
    }
  };

  const handleOTPVerificationSubmit = async () => {
    const result = await otpVerify(otp, phoneNumber);
    const { status, error } = result || {};
    if (status === 200) {
      setOpenOtpModal(false);
      setShowPhoneVerificationModal(false);
      notification.success({ message: "Phone has been verified" });
    } else {
      notification.error({ message: error });
    }
  };

  const handlePhoneVerificationSkip = () => {
    setShowPhoneVerificationModal(false);
  };

  const handlePhoneNumberChange = (event) => {
    const { value } = event.target;
    setPhoneNumber(value);
  };

  const handleResendClick = () => {
    setOtp("");
    setResendDisabled(true);
    setSecondsRemaining(30);
    handlePhoneVerificationSubmit();
  };
  useEffect(() => {
    if (userData === "SignUp") {
      setShowPhoneVerificationModal(true);
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setResendDisabled(false); // Enable resend button after 30 seconds
    }, 30000);

    const interval = setInterval(() => {
      setSecondsRemaining((prevSeconds) => prevSeconds - 1); // Update countdown timer every second
    }, 1000);

    return () => {
      clearTimeout(timer); // Clear the timer on component unmount
      clearInterval(interval); // Clear the interval on component unmount
    };
  }, []); // Run this effect only once on component mount

  useEffect(() => {
    if (secondsRemaining === 0) {
      setResendDisabled(false); // Enable resend button when timer reaches 0
    }
  }, [secondsRemaining]); // Update when secondsRemaining changes

  return (
    <div>
      <MainSlider />
      <HomeCategories />
      <PromotionBanner />
      <HomePromoted />
      <HomeOffers />
      <HomeLatest />
      <HomeDownload />
      <FeaturedCard />

      <PhoneVerificationModal
        visible={showPhoneVerificationModal}
        onCancel={() => setShowPhoneVerificationModal(false)}
        onSkip={handlePhoneVerificationSkip}
        onSubmit={handlePhoneVerificationSubmit}
        phoneNumber={phoneNumber}
        onChange={handlePhoneNumberChange}
        openOtpModal={openOtpModal}
      />

      <OTPVerificationModal
        openOtpModal={openOtpModal}
        onClose={() => setOpenOtpModal(false)}
        onResend={handleResendClick}
        onSubmit={handleOTPVerificationSubmit}
        otp={otp}
        setOtp={setOtp}
        resendDisabled={resendDisabled}
        secondsRemaining={secondsRemaining}
      />
    </div>
  );
}

export default Home;