import React, { useState } from "react";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import "./MyOrderDetails.css";
import { Button } from "@mui/material";
import { formatDateNew, getStatusColor } from "../../../Services/Utils/Utils";
import { useTranslation } from "react-i18next";
import CancelRequestModal from "../../CancelRequestModal/CancelRequestModal";
import ReviewModal from "../../ReviewModal/ReviewModal";

function MyOrderDetails({ orderDetailData, setActiveStep, dashboard,setOffsetCurrent }) {
  const { t, i18n } = useTranslation()
  const [visible, setVisible] = useState(false)
  const [showReviewModal, setShowReviewModal] = useState(false)
  const [reviewData, setReviewData] = useState({})

  const handleCancelOrder = () => {
    setVisible(true)
  }

  const handleReviewOrder = (productId) => {
    setShowReviewModal(true)
    setReviewData({ orderId: orderDetailData ?._id, productId })
  }

  const handleOrderBack = () => {
    if (dashboard) {
      setActiveStep(0)
    }
    else {
      if (orderDetailData ?.status === "DELIVERED") {
        setActiveStep(1)
      }
      else if (orderDetailData ?.status === "PENDING" || orderDetailData ?.status === "CANCELLATION") {
        setActiveStep(0)
      }
      else {
        setActiveStep(2)
      }
    }


  }
  const cancelModal =()=>{
    // getData("current",0)
    
    setActiveStep(0)
  }

  return (
    <div className="orderDetails">
      <div className="backBox d-flex align-items-center">
        {i18n ?.language === "en" ? (
          <>
            <LeftOutlined onClick={handleOrderBack} />
            <h4>{t("MyOrderDetMess1")} # {orderDetailData ?.order_no}</h4>
          </>
        ) : (
            <>
              <RightOutlined onClick={handleOrderBack} />
              <h4>{t("MyOrderDetMess1")} # {orderDetailData ?.order_no}</h4>
            </>
          )}
      </div>
      <div className="statusBox">
        <p>
        {t("MyOrderDetMess1")} <b>{orderDetailData ?.order_no}</b> {t("MyOrderDetMess2")} <b>{formatDateNew(orderDetailData ?.t)}</b>  {t("MyOrderDetMess3")} <span style={{ color: getStatusColor(orderDetailData?.status)?.color }}>{getStatusColor(orderDetailData?.status)?.text}</span>
        </p>
        {orderDetailData ?.status === "PENDING" && <Button onClick={handleCancelOrder}>{t("MyOrderDetCancelButton")}</Button>}
      </div>

      <h3>{t("MyOrderDetHead")}</h3>

      <div className="DetailsInfo">
        {orderDetailData ?.order_details ?.map(order => (
          <div className="productInfo">
            <div className="left">
              <h4>{i18n ?.language === "en" ? order ?.product ?.title ?.en : order ?.product ?.title ?.ar}</h4>
              {i18n ?.language === "en" ?
                <h5>{order ?.quantity} x {order ?.product ?.sub_total ?.value / order ?.quantity} {" "}
                  {order ?.product ?.sub_total ?.curr}</h5> :
                <h5>{order ?.quantity}   {order ?.product ?.sub_total ?.value / order ?.quantity}x {" "}
                  {order ?.product ?.sub_total ?.curr}</h5>  
            }
            </div>
            <div className="right">
              <p>
                {order ?.product ?.sub_total ?.value} {" "}
                {order ?.product ?.sub_total ?.curr}
              </p>
              {orderDetailData ?.status === "DELIVERED" && <Button variant="text" className="review-btn" onClick={() => handleReviewOrder(order ?.product ?.id)}>{t("MyOrderDetReviewButton")}</Button>}
            </div>
          </div>
        ))}
        <div className="cartInfo">
          <div>
            <h5>{t("MyOrderDetSubTotal")}</h5>
            <h4>
              {orderDetailData ?.subtotal ?.value} {" "}
              {orderDetailData ?.subtotal ?.curr}
            </h4>
          </div>
          <div>
            <h5>
              {t("MyOrderDetDiscount")} <span>{t("MyOrderDetDiscountLoyaltySpan")}</span>
            </h5>
            <h4>
              {orderDetailData ?.discount ?.loyalty_points ?.Amount ?.value > 0 && "-"} {" "}
              {orderDetailData ?.discount ?.loyalty_points ?.Amount ?.value} {" "}
              {orderDetailData ?.discount ?.loyalty_points ?.Amount ?.curr}
            </h4>
          </div>
          <div>
            <h5>
             {t("MyOrderDetDiscount")} <span>{t("MyOrderDetDiscountPromoSpan")}</span>
            </h5>
            <h4>
              {orderDetailData ?.discount ?.promo_code ?.Amount ?.value > 0 && "-"} {" "}
              {orderDetailData ?.discount ?.promo_code ?.Amount ?.value} {" "}
              {orderDetailData ?.discount ?.promo_code ?.Amount ?.curr}
            </h4>
          </div>

          <div>
            <h5>{t("MyOrderDetVat")}</h5>
            <h4>
              {orderDetailData ?.vat ?.value} {" "}
              {orderDetailData ?.vat ?.curr}
            </h4>
          </div>
          <div className="shipping">
            <h5>{t("MyOrderDetShipping")}</h5>
            <h4>
              {orderDetailData ?.shipping_price ?.value} {" "}
              {orderDetailData ?.shipping_price ?.curr}
            </h4>
          </div>
          <div>
            <h5>{t("MyOrderDetTotal")}</h5>
            <h4>
              {orderDetailData ?.cart_total ?.value} {" "}
              {orderDetailData ?.cart_total ?.curr}
            </h4>
          </div>
        </div>
      </div>
      <CancelRequestModal visible={visible} setVisible={setVisible} order_id={orderDetailData ?._id} handleOrderBack={cancelModal}  />
      <ReviewModal visible={showReviewModal} setVisible={setShowReviewModal} data={reviewData} />
    </div>
  );
}

export default MyOrderDetails;
