import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import DashboardTable from "../DashboardTable/DashboardTable";
import "./MyOrder.css";
import { getOrders } from "../../Services/Api/Api";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import MyOrderDetails from "./MyOrderDetails/MyOrderDetails";


export default function MyOrders() {
  const { t } = useTranslation();
  const steps = [t('MyOrderLabelCurrent'), t('MyOrderLabelDelivered'), t('MyOrderLabelCancelled')];
  const [limit, setLimit] = useState(5);
  const [offsetCurrent, setOffsetCurrent] = useState(0);
  const [offsetDelivered, setOffsetDelivered] = useState(0);
  const [offsetCancelled, setOffsetCancelled] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [orderDetailData, setOrderDetailData] = useState([]);
  const [allDataLoaded, setAllDataLoaded] = useState(false);

  useEffect(() => {
    if (activeStep === 1) {
      getData("delivered",offsetDelivered);
    } else if (activeStep === 2) {
      getData("cancelled",offsetCancelled);
    } else {
      getData("current",offsetCurrent);
    }
  }, [activeStep,offsetCurrent,offsetCancelled,offsetDelivered]);

  const getData = async (statusType,offset) => {
    if(offset !== undefined){
    const result = await getOrders(statusType, limit, offset);
    const { data, status, error } = result || {};
    if (status === 200) {
      setTableData(prevTableData => [...prevTableData, ...data?.orders]);
      if (data?.orders.length === 0) {
        setAllDataLoaded(true);
      }
      if(data?.orders.length < limit){
        setAllDataLoaded(true)
      }
      // setOffset(offset + limit);
    } else {
      console.log(error);
    }
  }
  };

  const handleStep = (step) => () => {
    setAllDataLoaded(false)
    
    if (step === 1) {
      setOffsetDelivered(0)
      getData("delivered");
      setTableData([]);
    } else if (step === 2) {
      setOffsetCancelled(0)
      getData("cancelled");
      setTableData([]);
    } else {
      setOffsetCurrent(0)
      getData("current");
      setTableData([]);
      
    }
    setActiveStep(step);
    
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <DashboardTable
            data={tableData}
            setLimit={setLimit}
            setOffset={setOffsetCurrent}
            getData={getData}
            allDataLoaded={allDataLoaded}
            setActiveStep={setActiveStep}
            setOrderDetailData={setOrderDetailData}
          />
        );
      case 1:
        return (
          <DashboardTable
            data={tableData}
            setLimit={setLimit}
            setOffset={setOffsetDelivered}
            getData={getData}
            allDataLoaded={allDataLoaded}
            setActiveStep={setActiveStep}
            setOrderDetailData={setOrderDetailData}
            current={"current"}
          />
        );
      case 2:
        return (
          <DashboardTable
            data={tableData}
            setLimit={setLimit}
            setOffset={setOffsetCancelled}
            getData={getData}
            allDataLoaded={allDataLoaded}
            setActiveStep={setActiveStep}
            setOrderDetailData={setOrderDetailData}
          />
        );
      case 3:
        return (
          <MyOrderDetails
            orderDetailData={orderDetailData}
            setActiveStep={setActiveStep}
            setOffsetCurrent={setOffsetCurrent}
          />
        );
      default:
        return null;
    }
  };

  
  return (
    <Box>
      {activeStep != 3 ? (
        <h4 className="my-order">{t("MyOrderHeading")}</h4>
      ) : null}
      {activeStep != 3 ? (
        <div className="d-flex pending-points ">
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <div
                className={`label ${activeStep === index ? "active" : ""}`}
                onClick={handleStep(index)}
              >
                {label}
              </div>
            </Step>
          ))}
        </div>
      ) : null}
      <div>{renderStepContent(activeStep)}</div>
    </Box>
  );
}
