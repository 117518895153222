import React, { useEffect, useState } from "react";
import "./HomeCategories.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation, Autoplay } from "swiper/modules";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { getAllCategory } from "../../../Services/Api/Api";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

function HomeCategories() {
  const location = useLocation();
  const { search } = location?.state || {};
  const { t, i18n } = useTranslation();
  const [categoriesData, setCategoriesData] = useState([]);
  const [baseURL, setBaseUrl] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await getAllCategory();
    const { data, status, error } = result || {};
    if (status === 200) {
      setBaseUrl(data?.base_url);
      setCategoriesData(data?.categories);
    } else {
      console.log("Internal server error");
    }
  };

  return (
    <div
      className={
        i18n.language != "ar" ? "homecategory" : "homecategory homecategory-ar"
      }
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="head-tbox">
              <h2>{t("HomeCategoryHead")}</h2>

              <div className="arrow-box">
                <div className="swiper-button image-swiper-button-next">
                  <ChevronRightIcon />
                </div>
                <div className="swiper-button image-swiper-button-prev">
                  <ChevronLeftIcon />
                </div>
              </div>
            </div>
            {categoriesData?.length != 0 ? (
              <Swiper
                navigation={{
                  nextEl: ".image-swiper-button-next",
                  prevEl: ".image-swiper-button-prev",
                  disabledClass: "swiper-button-disabled",
                }}
                // navigation={true}
                spaceBetween={15}
                slidesPerView={1}
                autoplay={{ delay: 2500, disableOnInteraction: false }}
                breakpoints={{
                  1920: { slidesPerView: 6, spaceBetween: 25 },
                  1680: { slidesPerView: 6, spaceBetween: 10 },
                  1536: { slidesPerView: 6, spaceBetween: 10 },
                  1440: { slidesPerView: 6, spaceBetween: 10 },
                  1366: { slidesPerView: 6, spaceBetween: 10 },
                  1280: { slidesPerView: 6, spaceBetween: 10 },
                  1152: { slidesPerView: 5, spaceBetween: 10 },
                  1024: { slidesPerView: 5, spaceBetween: 10 },
                  768: { slidesPerView: 4, spaceBetween: 10 },
                  767: { slidesPerView: 3, spaceBetween: 10 },
                  600: { slidesPerView: 2, spaceBetween: 10 },
                  428: { slidesPerView: 2, spaceBetween: 10 },
                  414: { slidesPerView: 2, spaceBetween: 10 },
                  360: { slidesPerView: 2, spaceBetween: 10 },
                  375: { slidesPerView: 2, spaceBetween: 10 },
                }}
                modules={[Navigation, Autoplay]}
                className="mySwiper"
                dir={i18n.language === "ar" ? "rtl" : "ltr"}
                key={i18n.language}
              >
                {categoriesData?.map((category, index) => (
                  <SwiperSlide key={index}>
                    <Link
                      to="/products"
                      state={{ category: category?._id, search: search }}
                      className="category-box"
                    >
                      <img
                        src={baseURL + category?.icon}
                        alt="category-image"
                      />
                      <h3>
                        {i18n?.language === "en"
                          ? category?.name?.en
                          : category?.name?.ar}
                      </h3>
                      <p>
                        {category?.low_quantity} {t("HomeProducts")}
                      </p>
                    </Link>
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              <div>
                <h5 className="not-found">{t("NoItemFound")}</h5>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeCategories;
