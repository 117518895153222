import React from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import "./BreadCrumbs.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const BreadCrumbs = ({ currentPage, category }) => {
  const { t, i18n } = useTranslation();

  const arrowsDir = () => {
    return i18n?.language === "en" ? (
      <KeyboardArrowRightIcon />
    ) : (
      <KeyboardArrowLeft />
    );
  };

  return (
    <div className="breadcrumb">
      <div className="container">
        <span className="home">
          <Link to="/">{t("BreadCrumpHome")}</Link>
        </span>
        {category && (
          <>
            <span className="arrow">{arrowsDir()}</span>
            <span className="home">
              <Link to={category?.link}>{category?.name}</Link>
            </span>
          </>
        )}
        <span className="arrow">{arrowsDir()}</span>
        <span className="currentPage">{currentPage}</span>
      </div>
    </div>
  );
};

export default BreadCrumbs;
