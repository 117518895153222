import React from "react";
import "./ForgotPassword.css";
import { Input, notification, Form, Button } from "antd";
import { forgotPassword } from "../../../Services/Api/Api";
import { useTranslation } from "react-i18next";
import { validateTextField, errMess } from "../../../Services/Utils/Utils";

const ForgotPassword = () => {
  const { t, i18n } = useTranslation();

  // Handle Forgot Password
  const onFinish = async (values) => {
    const result = await forgotPassword(values);
    const { status, data, error } = result || {};
    if (status === 200) {
      notification.success({ message: data?.message });
    } else {
      notification.error({ message: errMess(error) });
    }
  };

  return (
    <div className="forgot">
      <Form className="forgot-form" onFinish={onFinish}>
        <div className="forgot-heading">
          <h4>{t("ForgotHeading")}</h4>
        </div>
        <div className="forgot-password-heading">
          <p>{t("ForgotParagraph")}</p>
        </div>
        <div className="input-fields-forgot">
          <div className="custom-input">
            <Form.Item
              name="email"
              rules={[{ type: "email", validator: validateTextField }]}
            >
              <Input
                type="email"
                placeholder={t("ForgotPasswordPlaceholder")}
                prefix={<img src={"assets/images/email.png"} alt="Email" />}
              />
            </Form.Item>
          </div>
        </div>

        <div className="forgot-btn">
          <Button htmlType="submit">{t("ForgotButton")}</Button>
        </div>
      </Form>
    </div>
  );
};

export default ForgotPassword;