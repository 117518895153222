import React, { useEffect } from "react";
import { Modal, Button, Input } from "antd";
import "./PhoneVerificationModal.css";
import { useTranslation } from "react-i18next";

const PhoneVerificationModal = ({
  visible,
  onCancel,
  onSkip,
  onSubmit,
  phoneNumber,
  onChange,
  openOtpModal,
}) => {

  useEffect(() => {
    if (openOtpModal) {
      alert("Otp sent");
    }
  }, []);

  const { t, i18n } = useTranslation();

  return (
    <Modal
      title={t("PhoneVerificationHeading")}
      open={visible}
      onCancel={onCancel}
      centered
      className="verification-modal-s"
      footer={[
        <Button key="skip" onClick={onSkip}>
          {t("SkipButton")}
        </Button>,
        <Button key="verify" onClick={onSubmit}>
          {t("VerifyButton")}
        </Button>,
      ]}
    >
      <Input
        type="number"
        placeholder={t("VerifyNumberPlaceholder")}
        value={phoneNumber}
        onChange={onChange}
        maxLength={13} // Maximum length for international phone number
        pattern="\+[0-9]*" // Pattern to allow only numbers and +
      />
    </Modal>
  );
};

export default PhoneVerificationModal;
