import React, { useEffect } from 'react';
import './PendingPoints.css';
import { formatDate, formatTime } from '../../Services/Utils/Utils';
import { ArrowDownOutlined,ArrowUpOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useTranslation } from "react-i18next";

const PendingPoints = ({ pendingData,setLimit,loyaltyhistory,allDataLoaded }) => {
  const {t, i18n} = useTranslation()
  
  const handleLoadMore = () => {
    setLimit((prev) => prev + 5);
  };

  return (
    <div className="pending-points">
      {pendingData?.map((item, index) => {
        // Check if loyaltyhistory exists or earned.approved is false
        if (loyaltyhistory || !item?.loyalty_points?.earned?.approved) {
          return (
            <div key={index} className="row pending-row">
              <div className="col-12 pending-content">
                <div className="d-flex justify-content-between pending-data">
                  <h5 className="d-flex">
                    {item?.loyalty_points?.earned?.points} {" "}
                    {t("MyLoyaltyTablePendPoints")}
                    {loyaltyhistory && (
                      <span
                        className={
                          item?.loyalty_points?.earned?.approved
                            ? "arrow-up"
                            : "arrow-down"
                        }
                      >
                        {item?.loyalty_points?.earned?.approved ? (
                          <ArrowUpOutlined />
                        ) : (
                          <ArrowDownOutlined />
                        )}
                      </span>
                    )}
                  </h5>
                  <h6>{formatTime(item?.t)}</h6>
                </div>
                {loyaltyhistory && (
                  <div className="d-flex justify-content-between pending-data">
                    <h6>{t("MyLoyaltyTableBalance")} : {item?.loyalty_points?.earned?.points}</h6>
                  </div>
                )}
                <div className="d-flex justify-content-between pending-data">
                  <h6>{t("MyLoyaltyTableOrder")} # {item?.order_no}</h6>
                  <h6>{formatDate(item?.t)}</h6>
                </div>
              </div>
            </div>
          );
        } else {
          return null; 
        }
      })}
      {!allDataLoaded && pendingData.length > 0 && (
        <Button className="d-flex m-auto" onClick={handleLoadMore}>{t("LoadMore")}</Button>
      )}
    </div>
  );
};

export default PendingPoints