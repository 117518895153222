import { useEffect, useState } from "react";
import { addToWishlist, delWishlist, addRemoveQuantity, getCart } from "../Api/Api";
import { notification } from "antd";
import { setProductData, setWishlistCount, setCartCount } from '../Store/Store';

// Get User Details From Local Storage and Provide it to Whole App
export const userData = () => {
  const userData = JSON.parse(localStorage.getItem("data")) || {};
  const token = localStorage.getItem("token");
  const data ={
    token,
    ...userData
  }
  return data
};


// Handle Product Add and Del To Wishlist 
export const handleAddDelToWishlist = async ( wishlistData, setData, dispatch, token, navigate, wishlistCount ) => {
  if(!token) {
    navigate("/login")
    return
  }
  const { _id, favourite, cart_details } = wishlistData;
  let result = null;
  if (!favourite) {
    result = await addToWishlist(_id);
  } else {
    result = await delWishlist(_id);
  }
  const { data, status, error } = result || {};
  if (status === 200 && !favourite) {
    if (dispatch) {
      dispatch(setProductData({ p_id: _id, favourite: true, quantity: cart_details?.quantity }));
      dispatch(setWishlistCount(wishlistCount + 1));
    }
    notification.success({ message: data?.message });
  } else if (status === 200  && favourite) {
    if (dispatch) {
      dispatch(setProductData({ p_id: _id, favourite: false, quantity: cart_details?.quantity }));
      dispatch(setWishlistCount(wishlistCount - 1)); 
    }
    notification.success({ message: data?.success });
  } else {
    notification.error({ message: error });
  }
};


// Handle Product Quantity Add Subtract
export const handleProductsQuantity = async (product, operator, setData, dispatch, token, navigate, cartCount) => {
  if(!token) {
    navigate("/login")
    return
  }
  const { _id, favourite, cart_details } = product;
  const quantity = cart_details?.quantity || 0;
  const product_quantity = operator === "+" ? quantity + 1 : Math.max(quantity - 1, 0);
  try {
    const result = await addRemoveQuantity(_id, product_quantity);
    const { status, error } = result || {};
    if (status === 200) {
      if (dispatch) {
        dispatch(setProductData({ p_id: _id, quantity: product_quantity, favourite: favourite }));
        // Cart Count 
        const cart_result = await getCart();
        if (cart_result?.status === 200) {
          const cart_count = cart_result?.data?.cart_details?.length
          if(cart_count){
            dispatch(setCartCount(cart_count));
          }
        }
      }
    } else {
      notification.warning({ message: error });
    }
  } catch (error) {
    notification.error({ message: error?.message || "Unknown error occure" });
  }
};


// Ant-d Form Password Validator
export const validateConfirmPassword = (form) => (_, value) => {
  const { getFieldValue } = form;
  if (!value || !value.trim()) {
    return Promise.reject('You cannot leave this field blank');
  } else if (value && value !== getFieldValue("password")) {
    return Promise.reject("Confirm password do not match");
  }
  return Promise.resolve();
};


// Get Screen Width
export const useScreenWidth = () => {
  const [screenWidth, setScreenWidth] = useState(window?.innerWidth);
  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return screenWidth;
};


export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};
export const formatDateNew = (dateString) => {
  const options = { month: 'long', day: 'numeric', year: 'numeric' };
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', options);
};


export const formatTime = (timeString) => {
  const time = new Date(timeString);
  let hours = time.getHours();
  let minutes = time.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // 12-hour clock format
  minutes = minutes < 10 ? '0' + minutes : minutes;
  return `${hours}:${minutes} ${ampm}`;
};


// Ant-d Form Validate Text Field
export const validateTextField = (rule, value) => {
  return new Promise((resolve, reject) => {
    const { min, max, type } = rule;
    if (!value || !value.trim()) {
      reject('You cannot leave this field blank');
    } else if (type === "email" && !/\S+@\S+\.\S+/.test(value)) { // Check if the value is an invalid email
      reject('Invalid email');
    } else if (/^\d+$/.test(value.trim())) { // Check if the trimmed value contains only numbers
      reject('Value should not contain only numbers');
    }
    else if (/^\d+[A-Za-z]/.test(value.trim())) {
      reject('Value should not start with a number followed by letters');
    }
    else if (min !== undefined && value.length < min) {
      reject(`Should be ${min} characters minimum.`);
    } 
    // else if (/^[A-Za-z]+$/.test(value.trim())) {
    //   reject('Value should not contain only letters');
    // }
    else if (max !== undefined && value.length > max) {
      reject(`Can not be more than ${max} characters.`);
    } else {
      resolve();
    }
  });
};


// Clear Local Storage
export const clearLocalStorage = (navigate) => {
  localStorage.removeItem('data');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('token');
  if(navigate) {
    setTimeout(() => {
      navigate("/login")
    }, 1000);
  }
}


// Langugae Switch for (Responses which are Rejected)
export const errMess = (error) => {
  const language = localStorage.getItem('i18nextLng') || {};
  if(language === "en"){
    return error?.en || error || ""
  }
  else{
    return error?.ar || error || ""
  }
};

// My Account - Tables Get Status Colors
export const getStatusColor = (status) => {
  switch (status.toLowerCase()) {
    case "cancellation":
      return {color: "#ff0f0f", text: "Cancelled"}
    case "cancelled":
      return {color: "#ff0f0f", text: "Cancelled"}
    case "pending":
      return {color: "#fa8b0c", text: "Pending"}
    case "processing":
      return {color: "#0052B4", text: "Processing"}
    case "delivered":
      return {color: "#00C225", text: "Delivered"}
    default:
      return {color: "#19322F", text: ""}
  }
};