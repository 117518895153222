import React from "react";
import "./HomeDownload.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function HomeDownload() {
  const { t, i18n } = useTranslation();

  return (
    <div className={i18n.language != "ar" ? "home-download" : "home-download home-download-ar"}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="downloadBox">
              <h3>{t("HomeDownloadOurAppNow")}</h3>
              <p>
                {t("HomeDiscoverSubHead")}
              </p>
              <div className="appBox">
                <a href="#">
                  <img src="/assets/images/playStore.png" alt="playstore"/>
                </a>
                <a href="#">
                  <img src="/assets/images/appStore.png" alt="appstore"/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeDownload;
