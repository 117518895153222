import React, { useEffect, useState } from "react";
import "./WishlistTable.css";
import { Button, IconButton } from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import { addRemoveQuantity, delWishlist, getWishlist } from "../../Services/Api/Api";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import { setWishlistCount, setCartCount } from "../../Services/Store/Store";
import { useDispatch } from "react-redux";

const WishlistTable = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [baseUrl, setBaseUrl] = useState("");

  // Add or Remove Product Quantity
  const quantityHandler = async (_id, product_quantity) => {
    const result = await addRemoveQuantity(_id, product_quantity);
    const { status, data, error } = result || {};
    if (status === 200) {
      setTableData((prev) => {
        const indexToUpdate = prev.findIndex(
          (item) => item?.product_id === _id
        );
        if (indexToUpdate !== -1) {
          const updatedData = [...prev];
          updatedData[indexToUpdate].cart_details.quantity = product_quantity;
          dispatch(setCartCount(data?.cart?.cart_details?.length));
          return updatedData;
        }
        return prev;
      });
      notification.success({ message: data?.message });
    } else {
      notification.error({ message: error });
    }
  };

  // Get Wishlist Date
  const getData = async () => {
    setIsLoading(true);
    const result = await getWishlist();
    const { data, status, error } = result || [];
    if (status == 200) {
      setBaseUrl(data?.base_url);
      setTableData(data?.wishlist);
      dispatch(setWishlistCount(data?.wishlist?.length));
    } else {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  // Remove Item from Wishlist
  const removeItem = async (id) => {
    const response = await delWishlist(id);
    const { data, status, error } = response;
    if (status == 200) {
      setTableData((prev) => prev?.filter((item) => item?.product_id !== id));
      dispatch(setWishlistCount(tableData.length - 1));
      notification.success({ message: data?.success });
    } else {
      notification.error({ message: error });
    }
  };

  return (
    <div className="wishlist-table">
      <div className="table-responsive">
        {tableData.length > 0 || isLoading ? (
          <table className="table table-bordered">
            <tbody>
              {tableData.map((item, index) => (
                <tr key={index}>
                  <td className="remove">
                    <IconButton
                      aria-label="delete"
                      onClick={() => removeItem(item?.product_id)}
                    >
                      <img src="/assets/images/close.png" alt="close"/>
                    </IconButton>
                  </td>
                  <td className="image">
                    <img src={baseUrl + item?.images[0]} alt="item"/>
                  </td>
                  <td className="details">
                    <h4 className="product-name">
                      {i18n.language === "en"
                        ? item?.title?.en
                        : item?.title.ar}
                    </h4>
                    <div className="price">
                      {item?.actual_price && (
                        <span>
                          {item?.price?.curr} {item?.actual_price}
                        </span>
                      )}
                      {item?.price?.curr} {item?.price?.value}
                    </div>
                  </td>

                  <td className="quantity">
                    <div className="quantity-count">
                      <Button
                        className="btn"
                        onClick={() =>
                          quantityHandler(
                            item?.product_id,
                            item?.cart_details?.quantity - 1
                          )
                        }
                        disabled={
                          item?.cart_details?.quantity === 0 ||
                          item?.cart_details?.quantity == undefined
                        }
                      >
                        <RemoveRoundedIcon />
                      </Button>
                      <div>
                        {item?.cart_details?.quantity || 0}
                      </div>
                      <Button
                        className="btn"
                        onClick={() =>
                          quantityHandler(
                            item?.product_id,
                            (item?.cart_details?.quantity || 0) + 1
                          )
                        }
                      >
                        <AddRoundedIcon />
                      </Button>
                    </div>
                  </td>
                  {/* <td className="action">
                    <Button
                      variant="contained"
                      startIcon={<img src="/assets/images/cart.png" alt="cart"/>}
                    >
                      Add to Cart
                    </Button>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="empty-wishlist">
            <h6> {t("MyWishlistEmptyHead")} </h6>
            <p> {t("MyWishlistEmptyPara")} </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default WishlistTable;
