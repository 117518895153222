import React, { useEffect } from "react";
import "./Contact.css";
import { Card, Input, Form } from "antd";
import BreadCrumbs from "../../Components/BreadCrumbs/BreadCrumbs";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { validateTextField } from "../../Services/Utils/Utils";
import { Link } from 'react-router-dom'

const Contact = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onFinish = async (values) => {
    // console.log(values);
  };

  return (
    <div className={i18n.language != "ar" ? "contact-us" : "contact-us contact-us-ar"}>
      <BreadCrumbs currentPage={t("HeaderContact")} />
      <div className="container custom-container">
        <div className="row custom-row">
          <div className="col-12 col-md-12 col-lg-4  custom-col1">
            <h4>{t("ContactHelpHeading")}</h4>
            <p>{t("ContactHelpParagraph")}</p>
          </div>
          <div className="col-12 col-md-12 col-lg-4  custom-col2">
            <h6>01. {t("ContactVisitHeading")}</h6>
            <p>{t("ContactVisitParagraph")}</p>
            <h6>03. {t("ContactBillingHeading")}</h6>
            <p>{t("ContactBillingParagraph")}</p>
          </div>
          <div className="col-12 col-md-12 col-lg-4  custom-col2">
            <h6>02. {t("ContactEmployerHeading")}</h6>
            <p>{t("ContactEmployerParagraph")}</p>
            <h6>04. {t("ContactGeneralHeading")}</h6>
            <p>{t("ContactGeneralParagraph")}</p>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12 col-md-12 col-lg-4 ">
            <Card>
              <div className="text-center card-content">
                <img
                  src={"assets/images/PhoneIcon.png"}
                  className="d-inline-block align-top mr-2"
                  alt="card"
                />
                <h6>{t("ContactPhoneHeading")}</h6>
                <p>{t("ContactOfficeHeading")}: {" "}
                  <Link to='tel:+123 456 7890'>+123 456 7890</Link>
                </p>
                <p>{t("ContactWarehouseHeading")}: {" "}
                  <Link to='tel:+123 456 7890'>+123 456 7890</Link>
                </p>
              </div>
            </Card>
          </div>
          <div className="col-12 col-md-12 col-lg-4 ">
            <Card>
              <div className="text-center card-content">
                <img
                  src={"assets/images/EmailAd.png"}
                  className="d-inline-block align-top mr-2"
                  alt="card"
                />
                <h6>{t("ContactEmailHeading")}</h6>
                <p>{t("ContactSupportHeading")}:  {" "} 
                  <Link to='mailto:support@kullufi.com'>support@kullufi.com</Link>
                </p>
                <p>
                  {t("ContactInfoHeading")}: {" "}
                  <Link to='mailto:info@optimusinternationalllc.com'>info@optimusinternationalllc.com</Link>
                </p>
              </div>
            </Card>
          </div>
          <div className="col-12 col-md-12 col-lg-4 addressBox-s">
            <Card>
              <div className="text-center card-content">
                <img
                  src={"assets/images/Address.png"}
                  className="d-inline-block align-top mr-2"
                  alt="card"
                />
                <h6>{t("ContactAddressHeading")} </h6>
                <p>Office: 1234St. Lorem Ipsum Tower, Dubai,</p>
                <p>United Arab Emirates</p>
              </div>
            </Card>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12 col-md-12 col-lg-7 contact-form">
            <Form className="contact-form" onFinish={onFinish}>
              <div className="contact-heading">
                <h3>{t("ContactTouchHeading")}</h3>
              </div>

              <div className="input-fields-contact">
                <Form.Item
                  name="first_name"
                  rules={[{ min: 4, validator: validateTextField }]}
                >
                  <Input placeholder={t('ContactFirstNamePH')} type="text" />
                </Form.Item>
                <Form.Item
                  name="last_name"
                  rules={[{ min: 4, validator: validateTextField }]}
                >
                  <Input placeholder={t('ContactLastNamePH')} type="text" />
                </Form.Item>
              </div>

              <div className="input-fields-contact">
                <Form.Item
                  name="email"
                  rules={[{ type: "email", validator: validateTextField }]}
                >
                  <Input placeholder={t('ContactEmailPH')} type="email" />
                </Form.Item>
                <Form.Item
                  name="mobile_number"
                  rules={[{ validator: validateTextField }]}
                >
                  <Input placeholder={t('ContactPhonePH')} type="number" />
                </Form.Item>
              </div>

              <div className="input-fields-contact">
                <Form.Item
                  name="subject"
                  rules={[{ min: 4, validator: validateTextField }]}
                >
                  <Input placeholder={t('ContactSubjectPH')} type="text" />
                </Form.Item>
              </div>

              <div className="input-fields-contact">
                <Form.Item
                  name="message"
                  rules={[{ min: 10, validator: validateTextField }]}
                >
                  <Input.TextArea
                    maxLength={1000}
                    placeholder={t('ContactMessagePH')}
                    className="contactText"
                    rows={8}
                  />
                </Form.Item>
              </div>

              <div className="contact-btn">
                <Button type="submit">{t("ContactButtonText")}</Button>
              </div>
            </Form>
          </div>
          <div className="col-12 col-md-12 col-lg-5 contact-image ">
            <img src={"assets/images/ContactForm.png"} alt="contact" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;