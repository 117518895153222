import React, {useEffect} from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./ProductCard.css";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { handleAddDelToWishlist, handleProductsQuantity }from "../../Services/Utils/Utils";
import { IconButton } from "@mui/material";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import { useTranslation } from "react-i18next";
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useSelector, useDispatch } from 'react-redux';
import { selectToken, selectProductData, selectWishlistCount, selectCartCount } from '../../Services/Store/Store';

const ProductCard = ({ itemData, baseURL, setData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation()
  const { search } = location?.state || {}
  const { t, i18n } = useTranslation();
  const token = useSelector(selectToken);
  const productData = useSelector(selectProductData);
  const wishlistCount = useSelector(selectWishlistCount);
  const cartCount = useSelector(selectCartCount);

  // Synchronizing Diff Components Based on Wishlist Add and Del
  useEffect(() => {
    if(productData){
      setData((prev) =>
        prev?.map((item) =>
          item?._id === productData?.p_id
            ? {
                ...item,
                favourite: productData?.favourite,
                cart_details: {...item, quantity: productData?.quantity}
              }
            : item
        )
      );
    }
  }, [productData]);

  return (
    <div className={i18n.language != "ar" ? `product-card` : 'product-card product-card-ar'}>
      <div className="featured-wishlist-wrapper">
        {itemData?.featured && <span className="featured">
        <img    
          src={
            i18n?.language === "en"
              ? "/assets/images/featured-image.png"
              : " /assets/images/featured-image-ar.png"
          } alt="featured"
        />
        </span>}
        <span className="wishlist">
          <IconButton className={itemData?.favourite ? "active" : ""}
            onClick={()=> handleAddDelToWishlist(itemData, setData, dispatch, token, navigate, wishlistCount)}
          >
            <FavoriteIcon />
          </IconButton>
        </span>
      </div>

      <div className="image">
        <Link to={`/product/details/${itemData?._id}`}>
          <img src={baseURL + itemData?.images[0]} alt="detail" />
        </Link>
      </div>

      <div className="product-info">
        <div className="text">
          {/* state={{ search : search, category: itemData?._id}} */}
          <Link to={`/product/details/${itemData?._id}`}>
            <h4 className="title">{i18n?.language === "en" ? itemData?.title?.en :  itemData?.title?.ar} </h4>
          </Link>
          <div className="price">
            {itemData?.price?.value && (
              <span className={itemData?.promoted ? "promoted" : ""}>
                {itemData?.price?.value} {itemData?.price?.curr}
              </span>
            )}
            
            {itemData?.promoted && (
              <span>
                {itemData?.discounted_price?.value}
                {itemData?.discounted_price?.curr}
              </span>
            )}
          </div>
        </div>
        <div className="button-price-wrapper">
          {itemData?.cart_details?.quantity ? (
            <div className="product-button-wrapper">
              <IconButton className="minus-button" onClick={() => { handleProductsQuantity(itemData, "-", setData, dispatch, token, navigate, cartCount); }} >
                <RemoveRoundedIcon sx={{ color: "#EB1933", fontSize: 20 }} />
              </IconButton>

              <div className="product-quantity">{itemData?.cart_details?.quantity}</div>

              <IconButton className="add-button" onClick={() => { handleProductsQuantity(itemData, "+", setData, dispatch, token, navigate, cartCount); }}>
                <AddRoundedIcon sx={{ color: "#EB1933", fontSize: 20 }} />
              </IconButton>
            </div>
          ) : (
            <div className="product-button-wrapper">
              <IconButton className="add-button" onClick={() => { handleProductsQuantity(itemData, "+", setData, dispatch, token, navigate, cartCount); }}>
                <AddRoundedIcon sx={{ color: "#EB1933", fontSize: 20 }} />
              </IconButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
