import React, { useState, useEffect } from "react";
import "./Policies.css";
import BreadCrumbs from "../../Components/BreadCrumbs/BreadCrumbs";
import { getPolicies } from "../../Services/Api/Api";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const Policies = () => {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState("");
  const location = useLocation();
  const policyType = location.pathname.split("/").pop();
  const breadcrumbText = {
    "terms": t("BreadCrumpTerms"),
    "privacy-policy": t("BreadCrumpPrivacyPolicy"),
    "refund-policy":  t("BreadCrumpRefundPolicy"),
    "shipping-policy": t("BreadCrumpShippingPolicy"),
  };

  useEffect(() => {
    getData();
    window.scrollTo(0,0)
  }, [policyType]);

// Terms / Privay Policy / Refund Policy / Shipping Policy  - Get Policies 
  const getData = async () => {
    try {
      let result;
      switch (policyType) {
        case "terms":
          result = await getPolicies("terms");
          setData(result?.data?.terms_and_condition);
          break;
        case "privacy-policy":
          result = await getPolicies("policies/privacy");
          setData(result?.data?.privacy_policy);
          break;
        case "refund-policy":
          result = await getPolicies("policies/refund");
          setData(result?.data?.refund_policy);
          break;
        case "shipping-policy":
          result = await getPolicies("policies/shipping");
          setData(result?.data?.shipping_policy);
          break;
        default:
          return;
      }
      if (result?.status !== 200) {
        console.log("Internal server error");
      }
    } catch (error) {
      console.error("Error fetching policies:", error);
    }
  };

  return (
    <div className="privacy-policy">
      <BreadCrumbs currentPage={breadcrumbText[policyType]} />
      <div className="container">
        <div className="row custom-row">
          <div className="col-md-12">
            <div className="content">
              <h4 className="title">
                {i18n?.language == "en" ? data?.title?.en : data?.title?.ar}
              </h4>
              <div
                className="description"
                dangerouslySetInnerHTML={{
                  __html:
                    i18n?.language === "en"
                      ? data?.description?.en
                      : data?.description?.ar,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Policies;