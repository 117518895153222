import React from "react";
import "./DashboardTable.css";
import { formatDate, getStatusColor } from "../../Services/Utils/Utils";
import { useTranslation } from "react-i18next";
import { Button } from "antd";

const DashboardTable = ({ data, tableheading, setLimit, getData, allDataLoaded, dashboard, setActiveStep, setOrderDetailData,setOffset }) => {
  const { t, i18n } = useTranslation();

  const handleLoadMore = () => {
    setOffset((prevLimit) => prevLimit + 5);
  };
  const handleOrderDetails = (items) => {
    if (!tableheading) {
      setOrderDetailData(items);
      setActiveStep(3);
    } else {
      setActiveStep(1);
      setOrderDetailData(items);
    }
  };

  return (
    <div className={ i18n?.language != "ar" ? "row dashboard" : "row dashboard row dashboard-ar" } >
      <h4>{tableheading}</h4>
      <div className="table-responsive">
        {data?.length === 0 ? (
          <div className="d-flex justify-content-center align-items-center">
            <p className="noData">{t("NoDataFound")}</p>
          </div>
        ) : (
          <table className="table">
            <tbody>
              <tr>
                <td>{t("DashboardRecentSubHead1")}</td>
                <td>{t("DashboardRecentSubHead2")}</td>
                <td>{t("DashboardRecentSubHead3")}</td>
                <td>{t("DashboardRecentSubHead4")}</td>
                <td>{t("DashboardRecentSubHead5")}</td>
              </tr>
              {data?.map((item) => (
                <tr key={item?.order_no}>
                  <td>{item?.order_no}</td>
                  <td>{formatDate(item?.t)}</td> {/* Format date here */}
                  <td style={{ color: getStatusColor(item?.status)?.color }}>{getStatusColor(item?.status)?.text}</td>
                  <td>{`${item?.cart_total?.value} ${item?.cart_total?.curr}`}</td>
                  <td>
                    <img
                      onClick={() => handleOrderDetails(item)}
                      src="/assets/images/Eye.png"
                      alt="View"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {!allDataLoaded  ? (
          <Button className="d-flex m-auto" onClick={handleLoadMore}>
            {t("LoadMore")}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default DashboardTable;
