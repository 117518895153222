import React, { useEffect, useState } from "react";
import "./MainSlider.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation, Autoplay, EffectFade } from "swiper/modules";
import "swiper/css/effect-fade";
import { Link } from "react-router-dom";
import { getBanner } from "../../../Services/Api/Api";
import { useTranslation } from "react-i18next";

function MainSlider() {
  const { t, i18n } = useTranslation();
  // const [data, setData] = useState([]);
  // const [baseURL, setBaseUrl] = useState("");

  // useEffect(() => {
  //   getData();
  // }, []);

  // const getData = async () => {
  //   const result = await getBanner();
  //   const { data, status, error } = result || {};
  //   if (status === 200) {
  //     setBaseUrl(data?.base_url);
  //     const sortedBannerData = data?.banners.sort(
  //       (a, b) => a.priority - b.priority
  //     );
  //     setData(sortedBannerData || []);
  //   } else {
  //     console.log("Internal server error");
  //   }
  // };

  return (
    <div className="main-slider">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <Swiper
              effect={"fade"}
              // speed= {1000}
              autoplay={{
                delay: 50000,
                disableOnInteraction: false,
              }}
              navigation={true}
              // pagination={{
              //   dynamicBullets: true,
              // }}
              modules={[
                Autoplay,
                EffectFade,
                // Pagination,
                Navigation,
              ]}
              className="main-banner"
            >
              <SwiperSlide>
              {i18n.language != "ar" ? (
                <img src="/assets/images/MainSlider1.png" alt="slide" />
                ) : (
                <img src="/assets/images/MainSlider1-ar.png" alt="slide" />
                 )}
                <div className="banner-content-wrapper">
                  <div className="container">

                    {i18n.language != "ar" ? (
                      <div className="banner-content">
                      <h4>Exclusive Offer</h4>
                      <h2>
                        {" "}
                        Upto <span>60%</span> Off{" "}
                      </h2>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.
                      </p>
                      <Link to="/products" className="btn btn-primary">
                        Shop Now
                      </Link>
                    </div>
                    ) : (
                      <div className="banner-content">
                      <h4>عرض حصري</h4>
                      <h2>
                        {" "}
                        يصل إلى <span>60%</span> عن{" "}
                      </h2>
                      <p>
                      في الحد الأدنى من النشاط الذي نمارسه، لا نبذل أي جهد على الإطلاق لما يترتب على ذلك.
                      </p>
                      <Link to="/products" className="btn btn-primary">
                      تسوق الآن
                      </Link>
                    </div>
                    )}
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
              {i18n.language != "ar" ? (
                <img src="/assets/images/MainSlider2.png" alt="slide" />
                ) : (
                  <img src="/assets/images/MainSlider2-ar.png" alt="slide" />
                 )}
                
                <div className="banner-content-wrapper">
                  <div className="container">

                  {i18n.language != "ar" ? (
                    <div className="banner-content">
                    <h4>Weekly Special Offer</h4>
                    <h2>
                      Flat <span>25%</span> Off
                    </h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore
                      magna aliqua.
                    </p>
                    <Link to="/products" className="btn btn-primary">
                      Shop Now
                    </Link>
                  </div>
                  ) : (
                    <div className="banner-content">
                      <h4>عرض خاص أسبوعي</h4>
                      <h2>
                      مستوي <span>25%</span> عن
                      </h2>
                      <p>
                      في الحد الأدنى من النشاط الذي نمارسه، لا نبذل أي جهد على الإطلاق لما يترتب على ذلك.
                      </p>
                      <Link to="/products" className="btn btn-primary">
                      تسوق الآن
                      </Link>
                    </div>
                  )}
                    
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
              {i18n.language != "ar" ? (
                <img src="/assets/images/MainSlider3.png" alt="slide" />
                ) : (
                <img src="/assets/images/MainSlider3-ar.png" alt="slide" />
                 )}
                <div className="banner-content-wrapper">
                  <div className="container">
                  {i18n.language != "ar" ? (
                      <div className="banner-content">
                      <h4>Weekly Special Offer</h4>
                      <h2>
                        Flat <span>25%</span> Off
                      </h2>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.
                      </p>
                      <Link to="/products" className="btn btn-primary">
                        Shop Now
                      </Link>
                    </div>
                  ) :
                  (
                    <div className="banner-content">
                    <h4>عرض خاص أسبوعي</h4>
                    <h2>
                    مستوي <span>25%</span> عن
                    </h2>
                    <p>
                    في الحد الأدنى من النشاط الذي نمارسه، لا نبذل أي جهد على الإطلاق لما يترتب على ذلك.
                    </p>
                    <Link to="/products" className="btn btn-primary">
                    تسوق الآن
                    </Link>
                  </div>
                )}
                    
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainSlider;
