import React, { useState } from "react";
import "./HomePromoted.css";
import { Link } from "react-router-dom";
import { getProducts,  }from "../../../Services/Api/Api";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ProductCard from "../../../Components/ProductCard/ProductCard";

function HomePromoted() {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState([]);
  const [baseURL, setBaseUrl] = useState("");

  useEffect(() => {
    getProductsData();
  }, []);

  const getProductsData = async () => {
    const filters = { promoted_products: true };
    const result = await getProducts(filters, 10, 0);
    const { status, data, error } = result || {};
    if (status === 200) {
      setData(data?.products);
      setBaseUrl(data?.base_url);
    } else {
      console.log("Internal server error");
    }
  };

  return (
    <div className={i18n.language != "ar" ? "home-promoted" : "home-promoted home-promoted-ar"}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="head-tbox">
              <h2>{t("HomePromotedProductsHead")}</h2>
              <Link to="/products">{t("HomeViewAll")}</Link>
            </div>

            <div className="products-wrapper">
              {data?.length != 0 ? (
                data?.map((itemData, id) => (
                  <div key={itemData?._id} className="col-md-2 col-sm-6">
                    <ProductCard itemData={itemData} baseURL={baseURL} setData={setData}/>
                  </div>
                ))
              ) : (
                <div className="w-100">
                  <h5 className="not-found">{t("NoItemFound")}</h5>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePromoted;
