import { createSlice, configureStore } from "@reduxjs/toolkit";

const initialTokenState = {
  token: localStorage.getItem("token") || null,
};

const initialWishlistDataState = {
  wishlistCount: 0,
};

const initialCartDataState = {
  cartCount: 0,
};

const initialProductDataState = {
  productData: null,
};

export const tokenSlice = createSlice({
  name: "token",
  initialState: initialTokenState,
  reducers: {
    setToken: (state, action) => {
      return {
        ...state,
        token: action.payload,
      };
    },
    clearToken: (state) => {
      return {
        ...state,
        token: null,
      };
    },
  },
});

export const wishlistCountSlice = createSlice({
  name: "wishlistCount",
  initialState: initialWishlistDataState,
  reducers: {
    setWishlistCount: (state, action) => {
      return {
        ...state,
        wishlistCount: action.payload,
      };
    },

    clearWishlistCount: (state) => {
      return {
        ...state,
        wishlistCount: 0,
      };
    },
  },
});

export const cartCountSlice = createSlice({
  name: "cartCount",
  initialState: initialCartDataState,
  reducers: {
    setCartCount: (state, action) => {
      return {
        ...state,
        cartCount: action.payload,
      };
    },

    clearCartCount: (state) => {
      return {
        ...state,
        cartCount: 0,
      };
    },
  },
});

export const productDataSlice = createSlice({
  name: "productData",
  initialState: initialProductDataState,
  reducers: {
    setProductData: (state, action) => {
      return {
        ...state,
        productData: action.payload,
      };
    },

    clearProductData: (state) => {
      return {
        ...state,
        productData: 0,
      };
    },
  },
});

// Configure the Redux store
const store = configureStore({
  reducer: {
    token: tokenSlice.reducer,
    wishlistCount: wishlistCountSlice.reducer,
    cartCount: cartCountSlice.reducer,
    productData: productDataSlice.reducer,
  },
});

export const { setToken, clearToken } = tokenSlice.actions;
export const { setWishlistCount, clearWishlistCount } =  wishlistCountSlice.actions;
export const { setCartCount, clearCartCount } = cartCountSlice.actions;
export const { setProductData, clearProductData } = productDataSlice.actions;

export const selectToken = (state) => state.token.token;
export const selectWishlistCount = (state) => state.wishlistCount.wishlistCount;
export const selectCartCount = (state) => state.cartCount.cartCount;
export const selectProductData = (state) => state.productData.productData;

export default store;
