import React from 'react'
import './DeleteAccount.css'
import { Button, message } from 'antd'
import { deleteUser } from '../../Services/Api/Api';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearToken } from '../../Services/Store/Store';
import { useTranslation } from "react-i18next";


const DeleteAccount = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleDeleteAccount = async () => {
    try {
      // Call your delete API here
      const response = await deleteUser(); // Assuming deleteAccount is your API function
      // Check the response and show success or error message accordingly
      if (response.status === 200) {
        // Show success message
        message.success('Account deleted successfully');
        localStorage.removeItem("token");
        localStorage.removeItem("data");
        localStorage.removeItem("refresh_token");
        dispatch(clearToken());
        setTimeout(() => {
          navigate('/login');
        }, 500);
      } else {
        // Show error message
        message.error('Failed to delete account');
      }
    } catch (error) {
      // Handle any errors
      console.error('Error deleting account:', error);
      // Show error message
      message.error('An error occurred while deleting account');
    }
  };

  const { t, i18n } = useTranslation();

  return (
    <div className='delete-account'>
        <h4>{t('DeleteAccountHeading')}</h4>
        <h5>{t('DeleteAccountSubHead')}</h5>
        <p>{t('DeleteAccountParagraph')}</p>
        <Button className='delete-btn' onClick={handleDeleteAccount}>{t('DeleteAccountButton')}</Button>
    </div>
  )
}

export default DeleteAccount
