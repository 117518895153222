import React from "react";
import { Dropdown, Space } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";

function HeaderMobile() {

    const { t, i18n } = useTranslation();
    const items = [
        {
          key: "1",
          label: (
              <Link to="/">{t("HeaderHome")}</Link>
          ),
        },
        // {
        //   key: "2",
        //   label: (
        //       <Link to="/products">{t("HeaderBrands")}</Link>
        //   ),
        //   },
        {
          key: "3",
          label: (
              <Link to="/products">{t("HeaderProducts")}</Link>
          ),
        },
        {
          key: "4",
          label:(
              <Link to="/about">{t("HeaderAbout")}</Link>
          ),
        },
        {
          key: "5",
          label:(
              <Link to="/contact-us">{t("HeaderContact")}</Link>
          ),
        },
      ];
    
    

  return (
    <div className={i18n.language != "ar" ? "mobileMenu" : "mobileMenu mobileMenu-ar"}>
      <Dropdown
        menu={{
          items,
        }}
      >
        <a onClick={(e) => e.preventDefault()}>
          <Space>
            <MenuIcon />
          </Space>
        </a>
      </Dropdown>
    </div>
  );
}

export default HeaderMobile;
