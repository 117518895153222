import React, { useEffect, useState } from "react";
import { Input, Select, Radio, Form, notification, Modal } from "antd";
import BreadCrumbs from "../../Components/BreadCrumbs/BreadCrumbs";
import "./Checkout.css";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getAddress, postCheckoutDetails, postCheckoutOrder } from "../../Services/Api/Api";
import StripeElement from "../../Services/PaymentGateway/Stripe/StripeElement";
import { useTranslation } from "react-i18next";

function Checkout() {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location?.state;
  const [shippingData, setShippingData] = useState([])
  const [shippingId, setShippingId] = useState(0)
  const [keys, setKeys] = useState([])

  useEffect(() => {
    if (!location?.state) {
      navigate("/cart");
    }
    window.scrollTo(0, 0);
  }, []);

  // const onChange = (value) => {
  //   console.log(`selected ${value}`);
  // };
  // const onSearch = (value) => {
  //   console.log("search:", value);
  // };

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  // const onSelect = (e) => {
  //   console.log(`checked = ${e.target.checked}`);
  // };

  const getShippingData = async () => {
    const result = await getAddress();
    const { data, status, error } = result || [];
    if (status == 200) {
      const defaultAddress = data?.filter(item => item?.default)
      setShippingData(defaultAddress[0]);
      setShippingId(defaultAddress[0]?._id);
    } else {
      console.log(error);
    }
  };

  useEffect(() => {
    getShippingData();
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      full_name: state?.contact_info?.name,
      phone_number: state?.contact_info?.phone,
      email: state?.contact_info?.email,
      // address_1: state?.shipping_address?.address_line1,
      // address_2: state?.shipping_address?.address_line2,
      // area: state?.shipping_address?.area,
      // state: state?.shipping_address?.state,
    })
  }, []);


  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = async (values) => {
    const result = await postCheckoutDetails(shippingId, values);
    const { status, data, error } = result || {};

    if (status === 200) {
      const result = await postCheckoutOrder(values);
      const { status, data, error } = result || {};

      if (status === 200) {
        // notification.success({ message: data?.message })
        setIsModalOpen(true);
        setKeys(data)
      } else {
        notification.error({ message: error })
      }
    } else {
      notification.error({message: error?.en || error })
    }
  };

  const changePayMethod = ({ target: { value } }) => {
    form.setFieldsValue({pay_method : value})
  }

  return (
    <div>
      <BreadCrumbs 
        category={{
          name: t("BreadCrumpCheckout"), 
          link:"/checkout" 
        }}
        currentPage={t("BreadCrumpOrderConfirm")} 
      />
      <div className={i18n.language != "ar" ? "checkout" : "checkout checkout-ar"}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <Form form={form} onFinish={onFinish}>
                <div className="checkout-wrapper">
                  <div className="checkout-left">
                    <div className="formOne">
                      <h3>{t("CheckoutContactInfo")}</h3>
                      <Form.Item
                        name="full_name"
                        rules={[
                          {
                            required: true,
                            message: "You cannot leave this field blank",
                          },
                        ]}
                      >
                        <Input placeholder={t("CheckoutFullName")} type="name" />
                      </Form.Item>
                      <Form.Item
                        name="phone_number"
                        rules={[
                          {
                            required: true,
                            message: "You cannot leave this field blank",
                          },
                        ]}
                      >
                        <Input placeholder={t("CheckoutPhoneNumber")} type="number" />
                      </Form.Item>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "You cannot leave this field blank",
                          },
                          {
                            type: "email",
                            message: "Invalid Email",
                          },
                        ]}
                      >
                        <Input placeholder={t("CheckoutEmailAddress")} type="email" />
                      </Form.Item>
                    </div>

                    <div className="formTwo">
                      <h3>{t("CheckoutBillingDetails")}</h3>
                      <Form.Item
                        name="address_1"
                        rules={[
                          {
                            required: true,
                            message: "You cannot leave this field blank",
                          },
                        ]}
                      >
                        <Input placeholder={t("CheckoutAddressLine1")} type="" />
                      </Form.Item>
                      <Form.Item
                        name="address_2"
                        rules={[
                          {
                            required: true,
                            message: "You cannot leave this field blank",
                          },
                        ]}
                      >
                        <Input placeholder={t("CheckoutAddressLine2")} type="" />
                      </Form.Item>
                      <Form.Item
                        name="area"
                        rules={[
                          {
                            required: true,
                            message: "You cannot leave this field blank",
                          },
                        ]}
                      >
                        <Input placeholder={t("CheckoutArea")} type="" />
                      </Form.Item>
                      <Form.Item
                        name="state"
                        rules={[
                          {
                            required: true,
                            message: "You cannot leave this field blank",
                          },
                        ]}
                      >
                        <Select
                          placeholder={t("CheckoutSelectCountry")}
                          optionFilterProp="children"
                          // onChange={onChange}
                          // onSearch={onSearch}
                          filterOption={filterOption}
                          options={[
                            {
                              value: "emirates",
                              label: "Emirates",
                            },
                            {
                              value: "united states of america",
                              label: "United States Of America",
                            },
                            {
                              value: "canada",
                              label: "Canada",
                            },
                          ]}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="checkout-right">
                    <div className="cartInfo">
                      <div>
                        <h5>{t("CheckoutSubTotal")}</h5>
                        <h4>{state?.subtotal?.value} {state?.subtotal?.curr}</h4>
                      </div>

                      <div>
                        <h5>
                          {t("CheckoutsDiscount")} <span>{t("CheckoutLoyaltySpan")}</span>
                        </h5>
                        <h4> {state?.discount?.loyalty_points?.Amount?.value > 0 && "-"} {state?.discount?.loyalty_points?.Amount?.value} {state?.discount?.loyalty_points?.Amount?.curr} </h4>
                      </div>

                      <div>
                        <h5>
                          {t("CheckoutsDiscount")} <span>{t("CheckoutPromoSpan")}</span>
                        </h5>
                        <h4> {state?.discount?.promo_code?.Amount?.value > 0 && "-"} {state?.discount?.promo_code?.Amount?.value} {state?.discount?.promo_code?.Amount?.curr} </h4>
                      </div>

                      <div>
                        <h5>{t("CheckoutVAT")}</h5>
                        <h4> {state?.vat?.value} {state?.vat?.curr} </h4>
                      </div>

                      <div className="shipping">
                        <h5>{t("CheckoutShipping")}</h5>
                        <h4> {state?.shipping_price?.value} {state?.shipping_price?.curr} </h4>
                      </div>

                      <div>
                        <h5>{t("CheckoutTotal")}</h5>
                        <h4> {state?.cart_total?.value} {state?.cart_total?.curr} </h4>
                      </div>
                    </div>

                    <div className="shipAddress">
                      <div className="headBox">
                        <h3>{t("CheckoutShippingAddress")}</h3>
                        <Link to='/account' state={{key: "Cart"}}>
                          <Button>
                            <AddIcon />
                            {t("CheckoutAddNew")}
                          </Button>
                        </Link>
                      </div>

                      {shippingData && <>
                      <div className="address-box">
                        <div className="left">
                            <h4>{shippingData?.address_title}</h4>
                            <h5>{`
                              ${shippingData?.address_line1 || ""} 
                              ${shippingData?.address_line2 || ""} 
                              ${shippingData?.area || ""} 
                              ${shippingData?.state || ""}
                            `}</h5>
                        </div>

                        <div className="right">
                        <Link to='/account' state={{key: "Manage Addresses"}}>
                          <Button>
                            <img src="/assets/images/edit-icon.png" alt="edit"/>
                          </Button>
                        </Link>
                        </div>
                      </div>
                          </> }
                    </div>

                    <div className="paymentBox">
                      <Form.Item name="pay_method" 
                      //   rules={[
                      //     {
                      //       required: true,
                      //       message: "You cannot leave this field blank",
                      //     },
                      // ]}
                      >
                        <>
                        <h3>{t("CheckoutPaymentMethod")}</h3>
                        <Radio.Group 
                        onChange={changePayMethod}
                        value={"Credit / Debit Card"}
                        options={[
                          // {label: "Cash on Delivery", value: "Cash on Delivery"},
                          {label: t("CheckoutCreditDebitCard"), value: "Credit / Debit Card"},
                        ]} />
                        </>
                      </Form.Item>
                    </div>
                    
                    <Button className="orderBtn" type="submit">
                      {/* Place Order */}
                      {t("CheckoutPayWithCard")}
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title=" "
        centered
        maskClosable={false}
        closable={true}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        footer=""
      >
        <StripeElement
          keys={keys}
          onClose={setIsModalOpen}
          amount={state?.cart_total}
        />
      </Modal>
    </div>
  );
}

export default Checkout;
