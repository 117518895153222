import React, { useEffect, useState } from "react";
import "./OrderConfirmation.css";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import BreadCrumbs from "../../Components/BreadCrumbs/BreadCrumbs";
import { useLocation, useNavigate } from "react-router-dom";
import { getOrderById } from "../../Services/Api/Api";
import { notification } from "antd";
import { useTranslation } from "react-i18next";

function OrderConfirmation() {
  const location = useLocation();
  const navigate = useNavigate();
  const state = location?.state || {};
  const orderId = state?.id;
  const [data, setData] = useState({});
  const { t, i18n } = useTranslation()

  useEffect(() => {
    if (!location.state) {
      navigate("/");
    } else {
      getData();
      window.scrollTo(0, 0);
    }
  }, []);

  const getData = async () => {
    const result = await getOrderById(orderId);
    const { data, status, error } = result || {};
    if (status === 200) {
      setData(data?.order_details);
    } else {
      notification.error({ message: error });
    }
  };

  return (
    <div>
      <BreadCrumbs 
        category={{
          name: t("BreadCrumpCheckout"), 
          link:"/" 
        }}
        currentPage={t("BreadCrumpOrderConfirm")} 
      />
      <div className={i18n.language != "ar" ? "orderCondirmation" : "orderCondirmation orderCondirmation-ar"}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
            <div className="headBar">
              <h4>
                {t("OrderConfirmMess1")}
                <span className={`order-no-${i18n?.language}`}>
                  {data?.order_no}
                </span> 
                {t("OrderConfirmMess2")}
              </h4>
            </div>

              <div className="orderWrapper">
                <div className="left">
                  <h3>{t("OrderConfirmHead")}</h3>

                  {data?.order_details?.map((item, i) => (
                    <div className="detailsBox" key={i}>
                      <h4>
                        {i18n?.language === "en" ? item?.product?.title?.en : item?.product?.title?.ar}
                        <span className={`item-quantity-${i18n?.language}`}>
                            {item?.quantity} 
                            <span>x</span>
                            {item?.product?.sub_total?.value / item?.quantity} 
                            <span>{item?.product?.sub_total?.curr}</span>
                        </span>
                      </h4>
                      <h5>
                        {item?.product?.sub_total?.value}{" "}
                        {item?.product?.sub_total?.curr}
                      </h5>
                    </div>
                  ))}
                </div>

                <div className="right">
                  <div className="cartInfo">
                    <div>
                      <h5>{t("OrderConfirmSubTotal")}</h5>
                      <h4>
                        {data?.subtotal?.value} {data?.subtotal?.curr}
                      </h4>
                    </div>

                    <div>
                      <h5>
                        {t("OrderConfirmDiscount")} <span>{t("OrderConfirmDiscountLoyaltySpan")}</span>
                      </h5>
                      <h4>
                        {data?.discount?.loyalty_points?.Amount?.value > 0 &&
                          "-"}{" "}
                        {data?.discount?.loyalty_points?.Amount?.value}{" "}
                        {data?.discount?.loyalty_points?.Amount?.curr}
                      </h4>
                    </div>

                    <div>
                      <h5>
                        {t("OrderConfirmDiscount")} <span>{t("OrderConfirmDiscountPromoSpan")}</span>
                      </h5>
                      <h4>
                        {data?.discount?.promo_code?.Amount?.value > 0 && "-"}{" "}
                        {data?.discount?.promo_code?.Amount?.value}{" "}
                        {data?.discount?.promo_code?.Amount?.curr}
                      </h4>
                    </div>

                    <div>
                      <h5>{t("OrderConfirmVat")}</h5>
                      <h4>
                        {data?.vat?.value} {data?.vat?.curr}
                      </h4>
                    </div>

                    <div className="shipping">
                      <h5>{t("OrderConfirmShipping")}</h5>
                      <h4>
                        {data?.shipping_price?.value}{" "}
                        {data?.shipping_price?.curr}
                      </h4>
                    </div>

                    <div>
                      <h5>{t("OrderConfirmTotal")}</h5>
                      <h4>
                        {data?.cart_total?.value} {data?.cart_total?.curr}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderConfirmation;