import React, { useEffect } from 'react'
import WishlistTable from '../WishlistTable/WishlistTable'
import './MyWishlist.css'
import { useTranslation } from "react-i18next";

const MyWishlist = () => {

  useEffect(() => {
    window.scrollTo(0,0)
  }, []);

  const { t, i18n } = useTranslation();
  return (
    <div className='my-wishlist'>
        <h4 className='heading'>{t('MyWishlistHeading')}</h4>
        <WishlistTable/>
    </div>
  )
}

export default MyWishlist