import React from "react";
import ReactImageGallery from "react-image-gallery";
import "./ImageGallery.css";
import "react-image-gallery/styles/css/image-gallery.css";

const ImageGallery = ({ images, baseURL }) => {
  // Conditionally create the image array
  const imgs = [];

  if (images && Array.isArray(images)) {
    images?.forEach((img) => {
      imgs?.push({
        original: baseURL + img,
        thumbnail: baseURL + img,
      });
    });
  }

  return (
    <div className="details-slider-wrapper">
      <div className="details-slider">
        <ReactImageGallery
          items={imgs}
          // thumbnailPosition="left"
          infinite={true}
          showPlayButton={false}
          showIndex={false}
          showFullscreenButton={false}
          lazyLoad={true}
          // showBullets={true}
          // customRightArrow={<CustomRightArrow />}
          // customLeftArrow={<CustomLeftArrow />}
        />
      </div>
    </div>
  );
};

export default ImageGallery;

// const CustomRightArrow = ({ onClick }) => (
//   <button
//     className="image-gallery-custom-arrow image-gallery-custom-arrow-right"
//     onClick={onClick}
//     aria-label="Next Slide"
//   >
//     <span>&#9658;</span>
//   </button>
// );

// const CustomLeftArrow = ({ onClick }) => (
//   <button
//     className="image-gallery-custom-arrow image-gallery-custom-arrow-left"
//     onClick={onClick}
//     aria-label="Previous Slide"
//   >
//     <span>&#9668;</span>
//   </button>
// );
