import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../Pages/Home/Home";
import Login from "../Pages/Auth/Login/Login";
import Signup from "../Pages/Auth/Signup/Signup";
import ForgotPassword from "../Pages/Auth/ForgotPassword/ForgotPassword";
import ResetPassword from "../Pages/Auth/ResetPassword/ResetPassword";
import Policies from "../Pages/Policies/Policies";
import Contact from "../Pages/Contact/Contact";
import About from "../Pages/About/About";
import Wishlist from "../Pages/Wishlist/Wishlist";
import Account from "../Pages/Account/Account";
import Cart from "../Pages/Cart/Cart";
import AllProducts from "../Pages/AllProducts/AllProducts";
import ProductDetails from "../Pages/ProductDetails/ProductDetails";
import AuthRoutes from "./AuthRoutes";
import { userData } from "../Services/Utils/Utils";
import PublicRoutes from "./PublicRoutes";
import Checkout from "../Pages/Checkout/Checkout";
import OrderConfirmation from "../Pages/OrderConfirmation/OrderConfirmation";
import Layout from "../Components/Layout/Layout";

const Auth = () => {
  const { token } = userData();
  return !!token;
};

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* Private Routes */}
        <Route element={<PublicRoutes Auth={Auth} />}>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
        </Route>

        {/* Protected Routes */}
        <Route element={<AuthRoutes Auth={Auth} />}>
          <Route path="/account" element={<Account />} />
          <Route path="/wishlist" element={<Wishlist />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/order-confirmation" element={<OrderConfirmation />} />
        </Route>

        {/* Public Routes */}
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Navigate replace to="/" />} />
        <Route path="/terms" element={<Policies />} />
        <Route path="/privacy-policy" element={<Policies />} />
        <Route path="/refund-policy" element={<Policies />} />
        <Route path="/shipping-policy" element={<Policies />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/products" element={<AllProducts />} />
        <Route path="/product/details/:id" element={<ProductDetails />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
