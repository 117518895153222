import React, { useState, useEffect } from "react";
import "./About.css";
import { Card } from "antd";
import FeaturedCard from "../../Components/FeaturedCard/FeaturedCard";
import BreadCrumbs from "../../Components/BreadCrumbs/BreadCrumbs";
import { getAboutUs } from "../../Services/Api/Api";
import { useTranslation } from "react-i18next";
import HomeDownload from "../../Components/Home/HomeDownload/HomeDownload"

const About = () => {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState("");

  useEffect(() => {
    getData();
    window.scrollTo(0,0)
  }, []);

  // Get About Contant
  const getData = async () => {
    const result = await getAboutUs();
    const { data, status, error } = result || {};
    if (status == 200) {
      setData(data?.about_us);
    } else {
      console.log("Internal server error");
    }
  };

  return (
    <div className="about-us">
      <BreadCrumbs currentPage={t("BreadCrumpAbout")} />
      <div className="container custom-container">
        <div className="about-hero">
          <div className="row custom-row">
            <div className="col-12 col-md-12 col-lg-6  custom-col1">
              <img
                src={"assets/images/About.png"}
                className="d-inline-block align-top mr-2"
                alt="Logo"
              />
            </div>
            <div className="col-12 col-md-12 col-lg-6  custom-class">
              <h4>{i18n?.language == "en" ? data?.title?.en : data?.title?.ar}</h4>
              {/* <p className="lead">
                Optimus International LLC is one of the most dynamic and ﬂexible
                general trading companies today
              </p> */}
              <p className="lead">
               {t('AboutWelcomeSubHeading')}
              </p>
              <div
                className="content"
                dangerouslySetInnerHTML={{
                  __html:
                    i18n?.language === "en"
                      ? data?.description?.en
                      : data?.description?.ar,
                }}
              />
            </div>
          </div>
        </div>
        <div className="about-cards">
          <div className="row d-flex justify-content-center">
            <div className="col-12 text-center custom-row-section2">
              <h4>{t('AboutProvideHeading')}</h4>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center">
              <Card className="my-3">
                <div className="text-center card-content">
                  <img
                    src={"assets/images/BestPrice.png"}
                    className="d-inline-block align-top mr-2"
                    alt="card"
                  />
                  <h6>{t('AboutBestPriceHeading')}</h6>
                  <p>
                    {t('AboutBestPriceParagraph')}
                  </p>
                </div>
              </Card>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center">
              <Card className="my-3">
                <div className="text-center card-content">
                  <img
                    src={"assets/images/Deal.png"}
                    className="d-inline-block align-top mr-2"
                    alt="card"
                  />
                  <h6>{t('AboutGreatDailyHeading')}</h6>
                  <p>
                    {t('AboutGreatDailyParagraph')}
                  </p>
                </div>
              </Card>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center">
              <Card className="my-3">
                <div className="text-center card-content">
                  <img
                    src={"assets/images/Wide.png"}
                    className="d-inline-block align-top mr-2"
                    alt="card"
                  />
                  <h6>{t('AboutAssortmentHeading')}</h6>
                  <p>
                    {t('AboutAssortmentParagraph')}
                  </p>
                </div>
              </Card>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center">
              <Card className="my-3">
                <div className="text-center card-content">
                  <img
                    src={"assets/images/EasyReturn.png"}
                    className="d-inline-block align-top mr-2"
                    alt="card"
                  />
                  <h6>{t('AboutReturnsHeading')}</h6>
                  <p>
                    {t('AboutReturnsParagraph')}
                  </p>
                </div>
              </Card>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center">
              <Card className="my-3">
                <div className="text-center card-content">
                  <img
                    src={"assets/images/Satisfaction.png"}
                    className="d-inline-block align-top mr-2"
                    alt="card"
                  />
                  <h6>{t('AboutSatisfactionHeading')}</h6>
                  <p>
                    {t('AboutSatisfactionParagraph')}
                  </p>
                </div>
              </Card>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center">
              <Card className="my-3">
                <div className="text-center card-content">
                  <img
                    src={"assets/images/Delivery.png"}
                    className="d-inline-block align-top mr-2"
                    alt="card"
                  />
                  <h6>{t('AboutDeliveryHeading')}</h6>
                  <p>
                    {t('AboutDeliveryParagraph')}
                  </p>
                </div>
              </Card>
            </div>
          </div>
        </div>
        <div className="row custom-row">
          <div className="col-12 col-md-12 col-lg-6  custom-class operations">
            <h4>{t('AboutOperationsHeading')}</h4>
            <p className="lead">
              {t('AboutOperationsSubHeading')}
            </p>
            <p className="content">
              {t('AboutOperationsParagraph')}
            </p>
          </div>
          <div className="col-12 col-md-12 col-lg-6  custom-col-operation">
            <img
              src={"assets/images/operations.png"}
              className="d-inline-block align-top mr-2"
              alt="Logo"
            />
            <div className="operation">
              <img
                src={"assets/images/OurOperations.png"}
                className="d-inline-block align-top mr-2 "
                alt="Logo"
              />
            </div>
          </div>
        </div>
      </div>
      <HomeDownload />
      <FeaturedCard />
    </div>
  );
};

export default About;