import { apiHeader } from "./Baseurl";

// Auth - Login
export const loginUser = async (values) => {
  let data;
  await apiHeader
    .post("/auth/token", {
      email: values.email,
      password: values.password,
      device_token: "web",
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Auth - Forgot
export const forgotPassword = async (values) => {
  let data;
  await apiHeader
    .post("/auth/forgotpwd", {
      email: values.email,
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Auth - Register
export const registerUser = async (values) => {
  const {first_name, last_name, confirm_password, password, email} = values
  let data;
  await apiHeader
    .post("/user/register", {
      full_name: `${first_name}  ${last_name}` ,
      email: email,
      password: password,
      password_confirmation: confirm_password,
      device_token: "web",
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Auth - Reset Password Api
export const resetPassword = async (values, token) => {
  let data;
  await apiHeader
    .post("/auth/forgotpwd/reset", {
      email: values.email,
      forgot_pwd_token: token,
      new_password: values.password,
      confirm_new_password: values.confirm_password,
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Auth - Refresh Token Api
export const refreshAccessToken = async (refreshToken) => {
  let data;
  await apiHeader
    .post("/auth/token/refresh", {
      refresh_token: refreshToken,
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

export const phoneUser = async (phone) => {
  let data;
  await apiHeader
    .post("/user/phone", {
      phone: phone,
      
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

export const otpVerify = async (otp,phoneNumber) => {
  let data;
  await apiHeader
    .post("/user/phone/verify", {
      phone: phoneNumber,
      code:otp
      
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// My Account
export const logoutUser = async (refresh_token) => {
  let data;
  await apiHeader
    .post("/auth/logout", {
      refresh_token: refresh_token,
      device_token: "web",
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

export const getUserProfile = async () => {
  let data;
  await apiHeader
    .get(`/user/profile`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

export const updateUserProfile = async (full_name) => {
  let data;
  await apiHeader
    .patch(`/user/profile`,{
      full_name: full_name
    })
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

export const passwordResetProfile = async (values) => {
  let data;
  await apiHeader
    .put(`/auth/password/reset`,{
      old_password: values.current_password,
      new_password: values.password,
      confirm_password: values.confirm_password
    })
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};
export const deleteUser = async () => {
  let data;
  await apiHeader
    .delete(`/user/account`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};
export const getOrders = async (status,limit,offset) => {
  let data;
  await apiHeader
    .get(`orders?status=${status}&limit=${limit}&offset=${offset}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};
export const getOrderById = async (id) => {
  let data;
  await apiHeader
    .get(`orders/${id}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

export const cancelOrder = async (id,reason,description) => {
  let data;
  await apiHeader
    .post(`/orders/${id}/cancel`, {
      reason_for_cancellation: reason,
      description: description
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};
export const getAddress = async () => {
  let data;
  await apiHeader
    .get(`/addresses`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};
export const deleteAddress = async (id) => {
  let data;
  await apiHeader
    .delete(`/addresses/${id}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

export const updateAddress = async (id) => {
  let data;
  await apiHeader
  .get(`/addresses/${id}`)
  .then((res)=>{
    data={
    data: res.data,
    status: res.status,
    };
  })
  .catch((e)=>{
if(e.response){
  data = {
    ...e.response.data,
    status: e.response.status,
  }
}
  });
  return data;
}
export const AddAddress = async (formData) => {
  let data;
  await apiHeader
  .post("/addresses/", {
    location: {
      lat: formData?.latitude,
      lon: formData?.longitude
    },
    address_title: formData?.addressTitle,
    address_line1: formData?.addressLine1,
    address_line2: formData?.addressLine2,
    area: formData?.area,
    state: formData?.emirate
  })
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

export const EditAddress = async (formData,addressId) => {
  let data;
  await apiHeader
  .put(`/addresses/${addressId}`, {
    location: {
      lat: formData?.latitude,
      lon: formData?.longitude
    },
    address_title: formData?.addressTitle,
    address_line1: formData?.addressLine1,
    address_line2: formData?.addressLine2,
    area: formData?.area,
    state: formData?.emirate
  })
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

export const defaultAddress = async (id) => {
  let data;
  await apiHeader
    .post(`/addresses/${id}/default`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};
//Category
export const getAllCategory = async () => {
  let data;
  await apiHeader
    .get(`/categories`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// Home - Products - Get Products Based On Filter
export const getProducts = async (filters, limit, offset) => {
  let data;
  await apiHeader
    .post(`/products/search?limit=${limit}&offset=${offset}&token=${localStorage.getItem("token") || ""}`, 
      filters
    )
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

export const getReviews = async (limit,offset) => {
  let data;
  await apiHeader
    .get(`/reviews?limit=${limit}&offset=${offset}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

export const postReview = async (values) => {
  let data;
  await apiHeader
    .post(`/reviews`, values)
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


export const getLoyaltyPoints = async (offset,limit) => {
  let data;
  await apiHeader
    .get(`/user/loyaltypoints/details?type=all&offset=${offset}&limit=${limit}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Product Details - Get Particuler Product Details
export const getProductsDetails = async (id) => {
  let data;
  await apiHeader
    .get(`/products/${id}?&token=${localStorage.getItem("token") || ""}`)
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Product Reviews
export const getProductReviews = async (id) => {
  let data;
  await apiHeader
    .get(`/products/${id}/reviews`)
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//Home Banner
export const getBanner = async () => {
  let data;
  await apiHeader
    .get(`/banners`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


export const getAboutUs = async () => {
    let data;
    await apiHeader
      .get(`/content/aboutus`)
      .then((res) => {
        data = {
          data: res.data,
          status: res.status,
        };
      })
      .catch((e) => {
        if (e.response) {
          data = {
            ...e.response.data,
            status: e.response.status,
          };
        }
      });
    return data;
  };


// Terms / Privay Policy / Refund Policy / Shipping Policy  - Get Policies 
 export const getPolicies = async (policyType) => {
    let data;
    await apiHeader
      .get(`/content/${policyType}`)
      .then((res) => {
        data = {
          data: res.data,
          status: res.status,
        };
      })
      .catch((e) => {
        if (e.response) {
          data = {
            ...e.response.data,
            status: e.response.status,
          };
        }
      });
    return data;
  };
  

  export const getWishlist = async () => {
    let data;
    await apiHeader
      .get(`wishlist`)
      .then((res) => {
        data = {
          data: res.data,
          status: res.status,
        };
      })
      .catch((e) => {
        if (e.response) {
          data = {
            ...e.response.data,
            status: e.response.status,
          };
        }
      });
    return data;
  };
  export const getCart = async () => {
    let data;
    await apiHeader
      .get(`cart/details`)
      .then((res) => {
        data = {
          data: res.data,
          status: res.status,
        };
      })
      .catch((e) => {
        if (e.response) {
          data = {
            ...e.response.data,
            status: e.response.status,
          };
        }
      });
    return data;
  };

// Cart - Loyalty Points
export const redeemLoyaltyPoints = async () => {
  let data;
  await apiHeader
    .post(`/cart/loyaltypoints/redeem`)
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

export const unRedeemLoyaltyPoints = async () => {
  let data;
  await apiHeader
    .post(`/cart/loyaltypoints/unredeem`)
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

export const applyVoucher = async (id) => {
  let data;
  await apiHeader
    .post(`/cart/voucher/apply`, {
      "voucher_id": id
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

export const removeVoucher = async () => {
  let data;
  await apiHeader
    .post(`/cart/vouchers/remove`)
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

  //Home Brand
export const getBrands = async () => {
  let data;
  await apiHeader
    .get(`/brands`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// Home - Add Product to Wishlist
export const addToWishlist = async (id) => {
  let data;
  await apiHeader
    .post("/wishlist/items/", {
      product_id: id,
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Home - Delete Product to Wishlist
export const delWishlist = async (id) => {
  let data;
  await apiHeader
    .delete(`/wishlist/items/${id}`)
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// Home - Add or Rremove Product Quantity
export const addRemoveQuantity = async (_id, quantity) => {
  let data;
  await apiHeader
    .post(`/cart/items`, {
      quantity: quantity,
      product_id: _id,
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Remove Item from Cart
export const delCartItem = async (_id) => {
  let data;
  await apiHeader
    .delete(`/cart/items/${_id}`)
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Checkout Details
export const postCheckoutDetails = async (shippingId, values) => {
  let data;
  await apiHeader
    .post(`/cart/checkout/details`, {
      "shipping": {
        "id": shippingId
      },
      "delivery": {
        "scheduled": false,
        "delivery_date": "string",
        "delivery_time": "string"
      },
      "contact_info": {
        "name": values?.full_name,
        "phone": values?.phone_number,
        "email": values?.email
      }
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Checkout Order
export const postCheckoutOrder = async (values) => {
  let data;
  await apiHeader
    .post(`/cart/checkout`, {
      "billing_address": {
        "first_name": values?.full_name,
        "last_name": values?.full_name,
        "address_line1": values?.address_1,
        "address_line2": values?.address_2,
        "area": values?.area,
        "state": values?.state
      }
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Verify Payment
export const verifyPayment = async (paymentId) => {
  let data;
  await apiHeader
    .get(`/cart/verifypayment/${paymentId}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};