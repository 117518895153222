import React, { useState, useEffect } from "react";
import { Input, Space, Button } from "antd";
import { Link, useNavigate, useLocation  } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getWishlist, getCart }from "../../Services/Api/Api";
import { useSelector, useDispatch } from 'react-redux';
import { selectWishlistCount, setWishlistCount, selectCartCount, setCartCount, selectToken } from '../../Services/Store/Store';
const { Search } = Input;

function HeaderTop() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { search } = location?.state || {};
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const wishlistCount = useSelector(selectWishlistCount);
  const cartCount = useSelector(selectCartCount);
  const [searchQuery, setSearchQuery] = useState("");
  
    useEffect(() => {
    if(token){
      getWishlistData();
      getCartData();
    }
  }, []);

  const getWishlistData = async () => {
    const result = await getWishlist();
    const { data, status, error } = result || {};
    if (status == 200) {
        dispatch(setWishlistCount(data?.wishlist?.length))
    } else {
        console.log("Internal server error");
    }
  };

  const getCartData = async () => {
    const result = await getCart();
    const { data, status, error } = result || {};
    if (status == 200) {
        dispatch(setCartCount(data?.cart_details?.length))
    } else {
        console.log("Internal server error");
    }
  };

  useEffect(() => {
    setSearchQuery(search)
  }, [search])

  // Access state values
  const handleSearch = (value) => {
      navigate('/products',
       { state: { search: value?.toLowerCase() } 
      });
  };

  // Language Change Fun (i18n)
  const handleChangeLanguage = async (lng) => {
    if (lng) {
      i18n.changeLanguage(lng);
      localStorage.setItem("languageDirection", i18n.dir());
      document.body.dir = i18n.dir();
    } else {
      i18n.changeLanguage("en");
    }
  };

  return (
    <div className="header-top">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <div className="logo-box">
              <Link to="/">
                <img src="/assets/images/Logo.png" alt="Logo" />
              </Link>
            </div>
          </div>

          <div className="col-md-5">
            <div className="head-search">
              <Space direction="vertical">
                <Search
                  placeholder={t("HeaderSearchPH")}
                  onSearch={handleSearch}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  style={{
                    width: 200,
                  }}
                  enterButton
                />
              </Space>
            </div>
          </div>

          <div className="col-md-4">
            <div className="head-account">
              <ul>
                <li>
                  <Link to="/cart" state={{search : search}} >
                    <img src="/assets/images/bag.png" alt="Cart" />
                    {t("HeaderCart")}&nbsp;<span style={{direction: "ltr"}}>{cartCount ? `(${cartCount})` : null}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/wishlist" state={{search : search}}>
                    <img src="/assets/images/Heart.png" alt="Wishlist" />
                    {t("HeaderWishlist")}&nbsp;<span style={{direction: "ltr"}}>{wishlistCount ? `(${wishlistCount})` : null}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/account" state={{search : search}}>
                    <img src="/assets/images/User.png" alt="Account" />
                    {t("HeaderAccount")}
                  </Link>
                </li>
                <li>
                  {i18n?.language == "en" ? (
                    <div className="d-flex" onClick={() => handleChangeLanguage("ar")}>
                      <img src="/assets/images/ar-flag.png" alt="Language" />
                      <span> Ar</span>
                    </div>
                  ) : (
                    <div className="d-flex" onClick={() => handleChangeLanguage("en")}>
                      <img src="/assets/images/flag.png" alt="Language" />
                      <span> Eng</span>
                    </div>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderTop;
