import axios from "axios";
import { refreshAccessToken } from "./Api";

export const apiHeader = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
});

let isRefreshing = false;
let refreshSubscribers = [];

const subscribeTokenRefresh = (subscriber) => {
  refreshSubscribers.push(subscriber);
};

const onRefreshed = (token) => {
  refreshSubscribers.map((subscriber) => subscriber(token));
};

const handleTokenRefresh = async () => {
  if (!isRefreshing) {
    isRefreshing = true;
    try {
      const refreshToken = localStorage.getItem("refresh_token");
      if (!refreshToken) {
        clearLocalStorage();
        return;
      }
      const response = await refreshAccessToken(refreshToken);
      const { access_token, refresh_token } = response?.data || {};
      if (access_token) {
        localStorage.setItem("token", access_token);
        localStorage.setItem("refresh_token", refresh_token);
        onRefreshed(access_token);
        window.location.reload();
      } else {
        clearLocalStorage();
      }
    } catch (error) {
      console.error("Error refreshing access token:", error);
      clearLocalStorage();
    } finally {
      isRefreshing = false;
      refreshSubscribers = [];
    }
  }
};

apiHeader.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    config.headers["Accept-Language"] = "en";
    if (config.url === "/user/profile-image") {
      config.headers["Content-Type"] = "multipart/form-data";
    } else {
      config.headers["Content-Type"] = "application/json";
    }
    config.headers["X-localization"] = "en";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiHeader.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error?.config;
    const status = error?.response?.status;
    const err_message = error?.response?.data?.message;
    if (status === 401) {
      if (
        [ "Jwt Token Expired", "Invalid Token", "No authorization token was found", ]?.includes(err_message)
      ) {
        if (!isRefreshing) {
          handleTokenRefresh();
          return new Promise((resolve) => {
            subscribeTokenRefresh((token) => {
              originalRequest.headers.Authorization = `Bearer ${token}`;
              resolve(axios(originalRequest));
            });
          });
        } else {
          return new Promise((resolve) => {
            subscribeTokenRefresh((token) => {
              originalRequest.headers.Authorization = `Bearer ${token}`;
              resolve(axios(originalRequest));
            });
          });
        }
      } else if (err_message === "Account Deactivated") {
        clearLocalStorage();
      }
    }
    return Promise.reject(error);
  }
);

// Clear Local Storage
export const clearLocalStorage = () => {
  localStorage.removeItem("data");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("token");
  window.location.href = "/login";
};
