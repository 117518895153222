import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux'; 
import './index.css';
import "./Services/Languages/i18n";
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './Services/Store/Store';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}> 
      <Router> {/* Keep the Router for client-side routing */}
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
