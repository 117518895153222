import React, { useState } from "react";
import "./Account.css";
import { Button } from "react-bootstrap";
import BreadCrumbs from "../../Components/BreadCrumbs/BreadCrumbs";
import Dashboard from "../../Components/Dashboard/Dashboard";
import {
  AppstoreFilled,
  UserOutlined,
  ShoppingOutlined,
  LogoutOutlined,
  DeleteOutlined,
  EnvironmentOutlined,
  StarOutlined,
  GiftOutlined,
  ContainerOutlined,
  DesktopOutlined,
  MailOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PieChartOutlined,
  HeartOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import Profile from "../../Components/Profile/Profile";
import DeleteAccount from "../../Components/DeleteAccount/DeleteAccount";
import MyOrders from "../../Components/MyOrders/MyOrders";
import Reviews from "../../Components/Reviews/Reviews";
import LoyaltyPoints from "../../Components/LoyaltyPoints/LoyaltyPoints";
import ManageAddress from "../../Components/ManageAddress/ManageAddress";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserProfile, logout, logoutUser } from "../../Services/Api/Api";
import MyWishlist from "../../Components/MyWishlist/MyWishlist";
import { useDispatch } from "react-redux";
import { clearToken, clearWishlistCount, clearCartCount } from "../../Services/Store/Store";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const Account = ({ setShowPhoneVerificationModal }) => {

  const location = useLocation();
  const state = location?.state
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const { t, i18n } = useTranslation();
  const [current, setCurrent] = useState("Dashboard");
  const [fullName, setFullName] = useState("");
  const [userProfileData, setUserProfileData] = useState({});
  const refresh_token = localStorage.getItem("refresh_token") || {};

  useEffect(() => {
    if(state && state?.key === "Cart"){
      setCurrent("Manage Addresses")
      setActiveStep(1)
    } else if (state?.key === "Manage Addresses") {
      setCurrent(state?.key)
      setActiveStep(0)
    } else if (state?.key === "My Profile") {
      setCurrent(state?.key)
    }
    else{
      setActiveStep(0)
    }

    getData();
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const dataValue = searchParams.get("data");
    if (dataValue == "loyalty") {
      setCurrent("My Loyalty Points");
    }
  }, [location]);

  const getData = async () => {
    const result = await getUserProfile();
    const { data, status, error } = result || {};
    if (status == 200) {
      // console.log(data);
      setUserProfileData(data);
      setFullName(data?.full_name);
    } else {
      console.log(error);
    }
  };
  const Logout = async () => {
    try {
      const { status, data } = await logoutUser(refresh_token);
      if (status === 200) {
        localStorage.removeItem("token");
        localStorage.removeItem("data");
        localStorage.removeItem("refresh_token");
        dispatch(clearToken());
        dispatch(clearWishlistCount());
        dispatch(clearCartCount());
        setTimeout(() => {
          navigate("/login");
        }, 500);
      } else {
        console.log("Error");
      }
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };
  const menuItems = [
    {
      key: "Dashboard",
      label: t("DashboardSidebar1"),
      icon: <AppstoreFilled />,
    },
    {
      key: "My Profile",
      label: t("DashboardSidebar2"),
      icon: <UserOutlined />,
    },
    {
      key: "My Wishlist",
      label: t("DashboardSidebar3"),
      icon: <HeartOutlined />,
    },
    {
      key: "My Orders",
      label: t("DashboardSidebar4"),
      icon: <ShoppingOutlined />,
    },
    {
      key: "My Reviews",
      label: t("DashboardSidebar5"),
      icon: <StarOutlined />,
    },
    {
      key: "My Loyalty Points",
      label: t("DashboardSidebar6"),
      icon: (
        <img src="/assets/images/loyaltyPoints-icon.svg" className="anticon" />
      ),
    },
    {
      key: "Manage Addresses",
      label: t("DashboardSidebar7"),
      icon: (
        <img src="/assets/images/manageAddress-icon.svg" className="anticon" />
      ),
    },
    {
      key: "Delete Account",
      label: t("DashboardSidebar8"),
      icon: <img src="/assets/images/delete-icon.svg" className="anticon" />,
    },
    {
      key: "Logout",
      label: t("DashboardSidebar9"),
      icon: <img src="/assets/images/logOut-icon.svg" className="anticon" />,
    },
  ];

  const handleMenuClick = (key) => {
    if (key === "Logout") {
      Logout();
    } else {
      setCurrent(key);
    }
  };

  return (
    <div className={i18n.language != "ar" ? "account" : "account account-ar"}>
      <BreadCrumbs currentPage={t("BreadCrumpMyAccount")} />
      <div className="container mt-2 account">
        <div className="row account-row">
          <div className="col-md-3 col-sm-12 account-colsidebar">
            <Menu
              mode="inline"
              selectedKeys={[current]}
              onClick={({ key }) => handleMenuClick(key)}
              className={"custom-menu"}
            >
              {menuItems.map((item) => (
                <Menu.Item key={item.key} className="custom-menu-item">
                  {item.icon} {item.label}{" "}
                </Menu.Item>
              ))}
            </Menu>
          </div>
          <div className='col-md-9 col-sm-12 account-col'>
            {current === "Dashboard" && <Dashboard userProfileData={userProfileData} />}
            {current === "My Profile" && <div><Profile userProfileData={userProfileData} fullName={fullName} setFullName={setFullName} setShowPhoneVerificationModal={setShowPhoneVerificationModal} getData={getData} /></div>}
            {current === "My Wishlist" && <div><MyWishlist/></div>}
            {current === "My Orders" && <div><MyOrders /></div>}
            {current === "My Reviews" && <div><Reviews /></div>}
            {current === "My Loyalty Points" && <div><LoyaltyPoints userProfileData={userProfileData} /></div>}
            {current === "Manage Addresses" && <div><ManageAddress activeStep={activeStep} setActiveStep={setActiveStep} /></div>}
            {current === "Delete Account" && <div><DeleteAccount  /></div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;