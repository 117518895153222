import React from "react";
import "./HomeOffers.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function HomeOffers() {
  const { t, i18n } = useTranslation();
  return (
    <div className="homeOffers">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="offerBox">
              <h3>{t("HomeWeeklySpecialOffers")}</h3>
              <h4>{t("HomeOnVegetableandFruits")}</h4>
              <Link to="/">{t("HomeShopNow")}</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeOffers;
