import React from 'react';
import { Card } from 'react-bootstrap';
import './DashboardCard.css';

const DashboardCard = ({ data }) => {
    return (
        <div>
            <div className='row'>
                {data.map((item, index) => (
                    <div key={index} className='col-md-4 col-sm-6'>
                        <Card className='custom-card'>
                            <div className='d-flex justify-content-around mt-4'>
                                <div className='card-content-part'>
                                    <h6>{item.value}</h6>
                                    <p>{item.title}</p>
                                </div>
                                <img
                                    src={item.image}
                                    className="card-circle"
                                    alt="card"
                                />
                            </div>
                        </Card>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default DashboardCard;
