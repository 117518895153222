import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../Components/BreadCrumbs/BreadCrumbs";
import "./Cart.css";
import { Button, IconButton } from "@mui/material";
import { Input, InputNumber, Switch } from "antd";
import {
  addRemoveQuantity,
  applyVoucher,
  delCartItem,
  getCart,
  getUserProfile,
  redeemLoyaltyPoints,
  removeVoucher,
  unRedeemLoyaltyPoints,
} from "../../Services/Api/Api";
import { useTranslation } from "react-i18next";
import { notification } from "antd";
import { useNavigate } from "react-router-dom";
import { setCartCount } from "../../Services/Store/Store";
import { useDispatch } from 'react-redux';
import { errMess } from "../../Services/Utils/Utils";

const Cart = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [cartData, setCartData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [redeemLoyaltyEnabled, setRedeemLoyaltyEnabled] = useState(false);
  const [isCouponApplied, setisCouponApplied] = useState(false);
  const [voucherId, setvoucherId] = useState("");
  const [baseUrl, setBaseUrl] = useState();

  // Get Cart Data
  const getData = async () => {
    setIsLoading(true);
    const result = await getCart();
    const { data, status, error } = result || [];
    if (status == 200) {
      setBaseUrl(data?.base_url);
      setTableData(data?.cart_details);
      setCartData(data);
      dispatch(setCartCount(data?.cart_details?.length));
      setRedeemLoyaltyEnabled(data?.discount?.loyalty_points?.redeemed);
      setisCouponApplied(data?.discount?.promo_code?.coupon !== "");
    } else {
      notification.error({ message: error || "Internal Server Error" });
      console.log(error);
    }
    setIsLoading(false);
  };
  
  const checkPhoneVerification = async () => {
    setIsLoading(true);
    const result = await getUserProfile();
    const { data, status, error } = result || [];
    if (status == 200) {
      return data?.ipv
    } else {
      notification.error({ message: error || "Internal Server Error" });
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, [isCouponApplied, redeemLoyaltyEnabled]);

  useEffect(() => {
    window.scrollTo(0,0)
  }, []);

  // Add or Remove Product Quantity
  const onQuantityChange = async (_id, product_quantity) => {
    if (!product_quantity) return;
    const result = await addRemoveQuantity(_id, product_quantity);
    const { status, data, error } = result || {};
    if (status === 200) {
      setTableData(data?.cart?.cart_details);
      setCartData(data?.cart);
      dispatch(setCartCount(data?.cart?.cart_details?.length));
      notification.success({ message: data?.message });
    } else {
      notification.error({ message: error });
    }
  };

  // Delete Item from Cart
  const removeItem = async (id) => {
    const response = await delCartItem(id);
    const { data, status, error } = response;
    if (status == 200) {
      notification.success({ message: data?.message });
      // setTableData((prev) => prev?.filter((item) => item.product_id !== id));
      setTableData(data?.cart?.cart_details);
      setCartData(data?.cart);
      dispatch(setCartCount(data?.cart?.cart_details?.length));
    } else {
      notification.error({ message: error });
    }
  };

  // Redeem or UnRedeem Loyality Points on Purchase
  const loyaltyPointsHandler = async (checked) => {
    if (checked) {
      const response = await redeemLoyaltyPoints();
      const { data, status, error } = response || {};
      if (status == 200) {
        notification.success({ message: data?.success });
        setRedeemLoyaltyEnabled(checked);
      } else {
        notification.warning({ message: error });
      }
    } else {
      const response = await unRedeemLoyaltyPoints();
      const { data, status, error } = response || {};
      if (status == 200) {
        if (data?.error) {
          notification.warning({ message: data?.error });
        } else {
          notification.success({ message: data?.success });
        }
        setRedeemLoyaltyEnabled(checked);
      } else {
        notification.warning({ message: error });
      }
    }
  };

  // Apply Voucher or Remove Voucher Code on Purchase
  const discountHandler = async (e) => {
    e.preventDefault();
    if (!isCouponApplied) {
      const response = await applyVoucher(voucherId);
      const { data, status, error } = response || {};
      if (status == 200) {
        setisCouponApplied(true);
        notification.success({ message: data?.success });
      } else {
        notification.warning({ message: errMess(error) });
      }
    } else {
      const response = await removeVoucher();
      const { data, status, error } = response || {};
      if (status == 200) {
        setisCouponApplied(false);
        notification.success({ message: data?.success });
      } else {
        notification.warning({ message: errMess(error) });
      }
    }
  };

  // Navigate to Checkout Based on Phone Veification
  const navigateToCheckout = async () => {
    const verified = await checkPhoneVerification()
    if(verified){
      navigate("/checkout", { state: cartData });
    } else {
      notification.warning({message: t("CartPhoneValidateMess")})
      setTimeout(() => {
      navigate('/account', { state: {key: "My Profile"} })
      }, 2000);
    }
  };

  return (
    <div className={i18n.language != "ar" ? "cart" : "cart cart-ar"}>
      <BreadCrumbs currentPage={t("BreadCrumpCart")} />
      <div className="container custom-container">
        {tableData.length > 0 || isLoading ? (
          <div className="table-responsive">
            <table className="table cart-table">
              <thead>
                <tr>
                  <th className="cart-heading" scope="col"></th>
                  <th className="cart-heading" scope="col">
                    {/* Product */}
                    {t('CartTableHead1')}
                  </th>
                  <th className="cart-heading" scope="col">
                    {/* Price */}
                    {t('CartTableHead2')}
                  </th>
                  <th className="cart-heading" scope="col">
                    {/* Quantity */}
                    {t('CartTableHead3')}
                  </th>
                  <th className="cart-heading text-start" scope="col">
                    {/* Sub Total */}
                    {t('CartTableHead4')}
                  </th>
                  <th className="" scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {tableData?.map((item, index) => (
                  <tr key={index}>
                    <td className="remove">
                      <IconButton
                        aria-label="delete"
                        onClick={() => removeItem(item?.product?.id)}
                      >
                        <img src="/assets/images/close.png" alt="icon" />
                      </IconButton>
                    </td>
                    <td className="details">
                      <div className="details-wrapper">
                        <img
                          src={baseUrl + item?.product?.img}
                          alt={item?.title}
                        />
                        <h4 className="product-name">
                          {i18n.language === "en"
                            ? item?.product?.title?.en
                            : item?.product?.title?.ar}
                        </h4>
                      </div>
                    </td>
                    <td className="price">
                      <div>
                        {item?.product?.sub_total?.curr}{" "}
                        {item?.product?.sub_total?.value / item?.quantity}
                      </div>
                    </td>
                    <td className="quantity">
                      <div className="quantity-count">
                        <InputNumber
                          min={1}
                          size="large"
                          defaultValue={item?.quantity}
                          onChange={(qty) =>
                            onQuantityChange(item?.product?.id, qty)
                          }
                        />
                      </div>
                    </td>
                    <td className="subtotal">
                      {item?.product?.sub_total?.value}{" "}
                      {item?.product?.sub_total?.curr}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="empty-cart">
            <h6> {t("CartNoProductMess1")} </h6>
            <p> {t("CartNoProductMess1")} </p>
          </div>
        )}
        {tableData?.length > 0 && (
          <div className="cart-footer">
            <div className="col-left">
              <div className="wrapper">
                <div className="text">
                  <h4 className="title">{t('CartRedeem')}</h4>
                  <h6 className="desc">
                    {t("CartMess1")}{" "}
                    {cartData?.discount?.loyalty_points?.avaiable_points} {t("CartMess2")}
                  </h6>
                </div>
                <Switch
                  onChange={loyaltyPointsHandler}
                  checked={redeemLoyaltyEnabled}
                />
              </div>
              <form onSubmit={discountHandler}>
                <div className="discount-wrapper">
                  {!isCouponApplied ? (
                    <>
                      <Input
                        placeholder={t("CartDiscountCode")}
                        value={voucherId}
                        onChange={(e) => setvoucherId(e.target.value)}
                        required
                      />
                      <Button variant="contained" type="submit">
                        {t("CartApplyVoucher")}
                      </Button>
                    </>
                  ) : (
                    <Button
                      variant="contained"
                      className="remove-btn"
                      type="submit"
                    >
                      {t('CartRemoveVoucher')}
                    </Button>
                  )}
                </div>
              </form>
            </div>
            <div className="col-right">
              <div className="cart-total">
                <div>
                  <h6 className="heading"> {t('CartSubTotal')} </h6>
                  <span className="amount">
                    {cartData?.subtotal?.value} {cartData?.subtotal?.curr}{" "}
                  </span>
                </div>
                <div>
                  <h6 className="heading">
                    {t('CartDiscountLoyalty')} <span>{t('CartDiscountLoyaltySpan')}</span>
                  </h6>
                  <span className="amount">
                    {cartData?.discount?.loyalty_points?.Amount?.value > 0 && "-"}{" "}
                    {cartData?.discount?.loyalty_points?.Amount?.value}{" "}
                    {cartData?.discount?.loyalty_points?.Amount?.curr}
                  </span>
                </div>
                <div>
                  <h6 className="heading">
                    {t('CartDiscountPromo')} <span>{t('CartDiscountPromoSpan')}</span>
                  </h6>
                  <span className="amount">
                    {cartData?.discount?.promo_code?.Amount?.value > 0 && "-"}{" "}
                    {cartData?.discount?.promo_code?.Amount?.value}{" "}
                    {cartData?.discount?.promo_code?.Amount?.curr}
                  </span>
                </div>
                <div>
                  <h6 className="heading"> {t('CartVat')} </h6>
                  <span className="amount">
                    {cartData?.vat?.value} {cartData?.vat?.curr}
                  </span>
                </div>
                <hr className="divider" />
                <div>
                  <h6 className="heading"> {t('CartTotal')} </h6>
                  <span className="amount">
                    {cartData?.cart_total?.value} {cartData?.cart_total?.curr}
                  </span>
                </div>
              </div>
              <Button
                variant="contained"
                className="checkout-btn"
                onClick={navigateToCheckout}
              >
                {t('CartCheckoutButton')}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Cart;