import React, { useEffect, useState, useMemo } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { Button, CircularProgress } from "@mui/material";
import "./StripeElement.css";
import { notification } from "antd";
import { verifyPayment } from "../../Api/Api";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        fonts: [
          {
            cssSrc:
              "https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&display=swap",
            fontFamily: "Red Hat Display",
            fontWeight: "500",
          },
        ],
        hidePostalCode: true,
        base: {
          fontSize: "21px",
          color: "#504B4C",
          letterSpacing: "0.1em",
          fontFamily: "Red Hat Display",
          fontWeight: "500",
          "::placeholder": {
            color: "#D6D6D6",
          },
        },
        invalid: {
          color: "#ff4d4f",
          border: "1px solid #ff4d4f",
        },
      },
    }),
    []
  );
  return options;
};

export const CheckoutForm = ({ props }) => {
  const { onClose, keys, amount } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [paymentError, setPaymentError] = useState(null);
  const [isFormLoading, setIsFormLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setLoading(true);

    try {
      if (keys?.client_secret) {
        // Submit the payment form first
        const submitResult = await elements.submit();
        if (submitResult?.error) {
          setPaymentError(submitResult?.error?.message);
          setLoading(false);
          return;
        } else {
          setPaymentError(null);
        }

        // Confirm the payment with the PaymentIntent
        const { error, paymentIntent } = await stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url: `${window.location.origin}/`,
          },
          redirect: "if_required",
        });
        if (error) {
          setPaymentError(error?.message);
          setLoading(false);
        } else {
          if (paymentIntent?.status === "succeeded") {
            orderCreate(paymentIntent);
          }
        }
      }
    } catch (error) {
      setPaymentError(error?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (stripe && elements) {
      setIsFormLoading(false);
    }
  }, [stripe, elements]);

  // Order Purchase Confirm APi
  const orderCreate = async (paymentIntent) => {
    const orderResult = await verifyPayment(paymentIntent?.id);
    const {
      data: purchaseData,
      status: purchaseStatus,
      error: purchaseError,
    } = orderResult || {};

    if (purchaseStatus === 200) {
      setTimeout(() => {
        setLoading(false);
        onClose(false);
        const data = { id: keys?.order_id };
        window.history.replaceState(null, null, "/order-confirmation");
        navigate("/order-confirmation", { state: data });
        // notification.success({ message: purchaseData?.message?.en });
      }, 3000);
    } else {
      // notification.error({ message: purchaseError?.en ? purchaseError?.en : purchaseError, });
      notification.error({ message: t("CheckoutOrderErrMess") });
      setLoading(false);
    }
  };

  return (
    <div className="stripe">
      {isFormLoading ? (
        <div className="d-flex justify-content-center align-item-center mb-5">
          <CircularProgress style={{ color: "#00C225" }} size={30} />
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <PaymentElement
            id="payment-element"
            options={{ hidePostalCode: true }}
          />

          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!stripe || loading}
            className="pay-button"
          >
            {loading ? (
              <CircularProgress
                disableShrink
                size={24}
                sx={{ color: "white" }}
              />
            ) : (
              `${t("CheckoutPay")} ${amount?.value} ${amount?.curr}`
            )}
          </Button>
          {paymentError && <p className="error">{paymentError}</p>}
        </form>
      )}
    </div>
  );
};

function StripeElement(props) {
  const { i18n } = useTranslation();
  const { publishable_key, client_secret } = props?.keys;

  // Only render Stripe elements when publicKey is available
  const renderStripeElement = () => {
    if (!publishable_key || !client_secret) {
      return (
        <div className="d-flex justify-content-center align-item-center mb-5">
          <CircularProgress style={{ color: "#333333ec" }} size={30} />
        </div>
      );
    }
    
    const stripePromise = loadStripe(props?.keys?.publishable_key, {
      locale: i18n?.language === "en" ? "en" : "ar" || "en", //  Language Change
    });

    return (
      <Elements
        stripe={stripePromise}
        options={{ clientSecret: client_secret }}
      >
        <CheckoutForm props={props} />
      </Elements>
    );
  };

  return <>{renderStripeElement()}</>;
}
export default StripeElement;
