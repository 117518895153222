import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Footer.css";
import { useSelector } from "react-redux";
import { selectToken } from "../../Services/Store/Store";
import { useTranslation } from "react-i18next";
import { getAllCategory } from "../../Services/Api/Api";

function Footer() {
  const location = useLocation();
  const { search } = location?.state || {};
  const { t, i18n } = useTranslation();
  const token = useSelector(selectToken);
  const [categoriesData, setCategoriesData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await getAllCategory();
    const { data, status, error } = result || {};
    if (status === 200) {
      setCategoriesData(data?.categories);
    } else {
      console.log("Internal server error");
    }
  };

  return (
    <div className={i18n.language != "ar" ? "footer" : "footer footer-ar"}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="footer-wrapper">
              <div className="footer-box">
                <Link to="/">
                  <img
                    src="/assets/images/footer-logo.png"
                    alt=""
                    className="logo"
                  />
                </Link>

                <div className="social-box">
                  <h4>{t("FooterFollowText")}</h4>
                  <ul>
                    <li>
                      <a href="/" target="_blank">
                        {" "}
                        <img src="/assets/images/fb-icon.png" alt="icon" />{" "}
                      </a>
                    </li>

                    <li>
                      <a href="/" target="_blank">
                        {" "}
                        <img
                          src="/assets/images/insta-icon.png"
                          alt="icon"
                        />{" "}
                      </a>
                    </li>

                    <li>
                      <a href="/" target="_blank">
                        {" "}
                        <img
                          src="/assets/images/twitter-icon.png"
                          alt="icon"
                        />{" "}
                      </a>
                    </li>

                    <li>
                      <a href="/" target="_blank">
                        {" "}
                        <img
                          src="/assets/images/linkedin-icon.png"
                          alt="icon"
                        />{" "}
                      </a>
                    </li>

                    <li>
                      <a href="/" target="_blank">
                        {" "}
                        <img
                          src="/assets/images/youtube-icon.png"
                          alt="icon"
                        />{" "}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="footer-box">
                <h3>{t("FooterBoxHead1")}</h3>
                <ul>
                  <li>
                    <Link to="/about">{t("FooterCompanyOption1")}</Link>
                  </li>
                  <li>
                    <Link to="/shipping-policy">
                      {t("FooterCompanyOption2")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">
                      {t("FooterCompanyOption3")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/terms">{t("FooterCompanyOption4")}</Link>
                  </li>
                  <li>
                    <Link to="/contact-us">{t("FooterCompanyOption5")}</Link>
                  </li>
                  <li>
                    <Link to="/">{t("FooterCompanyOption6")}</Link>
                  </li>
                  <li>
                    <Link to="/">{t("FooterCompanyOption7")}</Link>
                  </li>
                </ul>
              </div>

              <div className="footer-box">
                <h3>{t("FooterBoxHead2")}</h3>
                <ul>
                  {!token && (
                    <li>
                      <Link to="/login">{t("FooterAccountOption1")}</Link>
                    </li>
                  )}
                  <li>
                    <Link to={token ? "/cart" : "/login"}>
                      {t("FooterAccountOption2")}
                    </Link>
                  </li>
                  <li>
                    <Link to={token ? "/wishlist" : "/login"}>
                      {t("FooterAccountOption3")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/">{t("FooterAccountOption4")}</Link>
                  </li>
                  <li>
                    <Link to={token ? "/account?data=loyalty" : "/login"}>
                      {t("FooterAccountOption5")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/">{t("FooterAccountOption6")}</Link>
                  </li>
                  <li>
                    <Link to="/">{t("FooterAccountOption7")}</Link>
                  </li>
                </ul>
              </div>

              <div className="footer-box">
                <h3>{t("FooterBoxHead3")}</h3>
                <ul>
                  {categoriesData.slice(0, 7)?.map((category, index) => (
                    <li key={index}>
                      <Link
                        to="/products"
                        state={{ category: category?._id, search: search }}
                      >
                        {i18n?.language === "en"
                          ? category?.name?.en
                          : category?.name?.ar}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="footer-box contact-info">
                <h3>{t("FooterBoxHead4")}</h3>
                <ul>
                  <li>
                    <img src="/assets/images/location-icon.png" alt="icon" />
                    1234St. Lorem Ipsum Tower, Dubai, United Arab Emirates.
                  </li>
                  <li>
                    <img src="/assets/images/email-icon.png" alt="icon" />
                    <Link to="mailto:info@optimusinternationalllc.com">
                      info@optimusinternationalllc.com
                    </Link>
                  </li>
                  <li>
                    <img src="/assets/images/phone-icon.png" alt="icon" />
                    <Link to="tel:+123 456 7890">+123 456 7890</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="row bottom-row">
          <div className="col-md-12">
            <div className="copyright">
              {i18n.language != "ar" ? (
                <p>
                  Copyright © 2024 <Link to="/">Kullufi</Link>. All Rights
                  Reserved. Powered By <Link to="branex.ae">Branex</Link>
                </p>
              ) : (
                <p>
                  حقوق النشر © 2024 <Link to="/">كلوفي</Link>.{" "}
                  <Link to="branex.ae">برانكس</Link>كل الحقوق محفوظة. مدعوم من{" "}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
