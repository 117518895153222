import React from "react";
import "./Login.css";
import { Button, Form, Input, notification } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import BreadCrumbs from "../../../Components/BreadCrumbs/BreadCrumbs";
import { loginUser } from "../../../Services/Api/Api";
import { useDispatch } from "react-redux";
import { setToken } from "../../../Services/Store/Store";
import { useTranslation } from "react-i18next";
import { validateTextField, errMess } from "../../../Services/Utils/Utils";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();

  // Handle Login
  const onFinish = async (values) => {
    const result = await loginUser(values);
    const { status, data, error } = result || {};
    const { access_token, refresh_token } = data?.token || {};
    if (status === 200) {
      localStorage.setItem("token", access_token);
      localStorage.setItem("refresh_token", refresh_token);
      localStorage.setItem("data", JSON.stringify(data));
      dispatch(setToken(access_token));
      navigate("/");
      notification.success({ message: "Login successful" });
    } else {
      notification.error({ message: errMess(error) });
    }
  };

  const handleSignUpClick = () => {
    navigate("/signup");
  };

  const handleForgotClick = () => {
    navigate("/forgot-password");
  };

  return (
    <div>
      <BreadCrumbs currentPage={t("Login")} />
      <div className="login">
        <Form form={form} className="login-form" onFinish={onFinish}>
          <div className="login-heading">
            <h3>{t("LoginHeading")}</h3>
          </div>

          <div className="input-fields">
            <Form.Item
              name="email"
              rules={[{ type: "email", validator: validateTextField }]}
            >
              <Input
                type="email"
                placeholder={t("LoginEmailPlaceholder")}
                prefix={<img src={"assets/images/email.png"} alt="Email" />}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[{ validator: validateTextField }]}
            >
              <Input.Password
                placeholder={t("LoginPasswordPlaceholder")}
                prefix={
                  <img src={"assets/images/passwordIcon.png"} alt="Password" />
                }
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
          </div>

          <div className="forgot-password">
            <p onClick={handleForgotClick}>{t("LoginForgotHeading")}</p>
          </div>

          <div className="login-btn">
            <Button htmlType="submit">{t("LoginHeading")}</Button>
          </div>
          <div className="dont-have-accont">
            <p>
              {t("LoginAccountText")}{" "}
              <span className="sign-up" onClick={handleSignUpClick}>
                {t("LoginSignupText")}
              </span>
            </p>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Login;