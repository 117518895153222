import React from "react";
import { Card } from "antd";
import { useTranslation } from "react-i18next";
import "./FeaturedCard.css";

const FeaturedCard = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="container">
      <div className="row mt-4 featured-card">
        <div className="col-lg-3 col-md-6 col-sm-12 d-flex justify-content-center">
          <Card className="customcard">
            <div className="text-center card-content">
              <img
                src={"assets/images/shipping.png"}
                className="d-inline-block align-top mr-2"
                alt="card"
              />
              <h6>{t("HomeFastFreeShipping")}</h6>
              <p>{t("HomeArounttheworld")}</p>
            </div>
          </Card>
        </div>

        <div className="col-lg-3 col-md-6 col-sm-12 d-flex justify-content-center">
          <Card className="customcard">
            <div className="text-center card-content">
              <img
                src={"assets/images/support.png"}
                className="d-inline-block align-top mr-2"
                alt="card"
              />
              <h6>{t("HomeSupports")}</h6>
              <p>{t("HomeContactus24hours")}</p>
            </div>
          </Card>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12 d-flex justify-content-center">
          <Card className="customcard">
            <div className="text-center card-content">
              <img
                src={"assets/images/money.png"}
                className="d-inline-block align-top mr-2"
                alt="card"
              />
              <h6>{t("HomeMoneyBack")}</h6>
              <p>{t("HomeGuarantee")}</p>
            </div>
          </Card>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12 d-flex justify-content-center">
          <Card className="customcard">
            <div className="text-center card-content">
              <img
                src={"assets/images/secure.png"}
                className="d-inline-block align-top mr-2"
                alt="card"
              />
              <h6>{t("HomeSecurePayment")}</h6>
              <p>{t("HomeYourPaymentAreSafe")}</p>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default FeaturedCard;
