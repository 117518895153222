import React, { useState, useEffect } from "react";
import "./HomeLatest.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation, Autoplay } from "swiper/modules";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { getProducts,  } from "../../../Services/Api/Api";
import { useTranslation } from "react-i18next";
import ProductCard from "../../../Components/ProductCard/ProductCard";

function HomeLatest() {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState([]);
  const [baseURL, setBaseUrl] = useState("");

  useEffect(() => {
    getLatestProductsData();
  }, []);

  const getLatestProductsData = async () => {
    // const filters = { featured_products: true, promoted_products: true };
    const filters = { };
    const result =await getProducts(filters, 50 , 0);
    const { status, data, error } =  result || {}
    if (status === 200) {
      setData(data?.products);
      setBaseUrl(data?.base_url);
    } else {
      console.log("Internal server error");
    }
  };

  return (
    <div className={i18n.language != "ar" ? "home-latest" : "home-latest home-latest-ar"}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="head-tbox">
              <h2>{t("HomeLatestProductsHead")}</h2>

              <div className="arrow-box">
                <div className="swiper-button image-swiper-button-next">
                  <ChevronRightIcon />
                </div>
                <div className="swiper-button image-swiper-button-prev">
                  <ChevronLeftIcon />
                </div>
              </div>
            </div>
            {data?.length != 0 ? (
              <Swiper
                navigation={{
                  nextEl: ".image-swiper-button-next",
                  prevEl: ".image-swiper-button-prev",
                  disabledClass: "swiper-button-disabled",
                }}
                // navigation={true}
                spaceBetween={15}
                slidesPerView={1}
                autoplay={{ delay: 2500, disableOnInteraction: false }}
                breakpoints={{
                  1920: {slidesPerView: 5, spaceBetween: 25,},
                  1680: {slidesPerView: 5, spaceBetween: 15,},
                  1536: {slidesPerView: 5, spaceBetween: 10, },
                  1440: {slidesPerView: 5, spaceBetween: 10, },
                  1366: {slidesPerView: 5, spaceBetween: 10, },
                  1280: {slidesPerView: 4, spaceBetween: 15, },
                  1152: {slidesPerView: 4, spaceBetween: 10, },
                  1024: {slidesPerView: 3, spaceBetween: 10, },
                  800: {slidesPerView: 2, spaceBetween: 10, },
                  768: {slidesPerView: 2, spaceBetween: 10, },
                  767: {slidesPerView: 1, spaceBetween: 10, },
                  600: {slidesPerView: 1, spaceBetween: 10, },
                  428: {slidesPerView: 1, spaceBetween: 10, },
                  414: {slidesPerView: 1, spaceBetween: 10, },
                  360: {slidesPerView: 1, spaceBetween: 10, },
                  375: {slidesPerView: 1, spaceBetween: 10, },
                }}
                modules={[Navigation, Autoplay]}
                className="mySwiper"
                dir={i18n.language === "ar" ? 'rtl': 'ltr'}
                key={i18n.language}
              >
                {data?.map((itemData, id) => (
                  <SwiperSlide key={id} >
                    <ProductCard itemData={itemData} baseURL={baseURL} setData={setData}/>
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              <div>
                <h5 className="not-found">{t("NoItemFound")}</h5>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeLatest;
