import React, { useEffect, useState } from "react";
import { LeftOutlined } from "@ant-design/icons";
import "./AddNewAddress.css";
import { Button, Input, Select, Form, notification } from "antd";
import { AddAddress } from "../../Services/Api/Api";
import { useTranslation } from "react-i18next";
import { validateTextField } from "../../Services/Utils/Utils";
const { Option } = Select;

const AddNewAddress = ({ handleStep, getData }) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const [selectedEmirate, setSelectedEmirate] = useState("");
  const [searchAddress, setSearchAddress] = useState("");
  const [formData, setFormData] = useState({
    locationTitle: "",
    addressTitle: "",
    addressLine1: "",
    addressLine2: "",
    area: "",
    emirate: "",
    latitude: "25.2048493",
    longitude: "55.2707828",
  });

  const handleSearchInputChange = (e) => {
    setSearchAddress(e.target.value);
  };

  const handleSearchAddress = () => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: searchAddress }, (results, status) => {
      if (status === "OK" && results[0]) {
        const latitude = results[0].geometry.location.lat();
        const longitude = results[0].geometry.location.lng();
        setFormData({
          ...formData,
          latitude: latitude.toString(),
          longitude: longitude.toString(),
        });
      } else {
        console.error(
          "Geocode was not successful for the following reason:",
          status
        );
      }
    });
  };

  // Handler function for selecting an emirate
  const handleEmirateChange = (value) => {
    setSelectedEmirate(value);
    setFormData({ ...formData, emirate: value });
  };

  // Function to handle input field changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Function to handle the Add button click
  const handleAddAddress = async () => {
    const result = await AddAddress(formData);
    const { status, error } = result || {};
    if (status === 200) {
      handleStep(0)();
      getData();
      // Reset the form data after successful addition
      setFormData({
        locationTitle: "",
        addressTitle: "",
        addressLine1: "",
        addressLine2: "",
        area: "",
        emirate: "",
        latitude: "25.2048493",
        longitude: "55.2707828",
      });
    } else {
      notification.error({ message: error || "Something Went Wrong" });
    }
  };

  useEffect(() => {
    const map = new window.google.maps.Map(document.getElementById("map"), {
      center: {
        lat: parseFloat(formData.latitude),
        lng: parseFloat(formData.longitude),
      },
      zoom: 15,
    });

    const marker = new window.google.maps.Marker({
      position: {
        lat: parseFloat(formData?.latitude),
        lng: parseFloat(formData?.longitude),
      },
      map: map,
      title: formData?.locationTitle,
      icon: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
    });

    map.addListener("click", (e) => {
      const latitude = e.latLng.lat();
      const longitude = e.latLng.lng();

      setFormData({ ...formData, latitude, longitude });

      new window.google.maps.Geocoder().geocode(
        { location: { lat: latitude, lng: longitude } },
        (results, status) => {
          if (status === "OK") {
            if (results[0]) {
              const locationTitle = results[0].formatted_address;
              setFormData({
                ...formData,
                locationTitle,
                latitude: latitude.toString(), // Convert latitude to string
                longitude: longitude.toString(), // Convert longitude to string
              });
              marker.setPosition({ lat: longitude, lng: longitude });
            }
          } else {
            console.error("Geocoder failed due to: " + status);
          }
        }
      );
    });

    // Add error handling
    window.google.maps.event.addListener(map, "error", function (event) {
      console.error("Map error:", event);
    });

    // Update location title only if latitude and longitude are not empty
    if (formData.latitude !== "" && formData.longitude !== "") {
      const geocoder = new window.google.maps.Geocoder();
      const latlng = {
        lat: parseFloat(formData.latitude),
        lng: parseFloat(formData.longitude),
      };
      geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            const locationTitle = results[0].formatted_address;
            setFormData((prevState) => ({ ...prevState, locationTitle }));
          }
        } else {
          console.error("Geocoder failed due to: " + status);
        }
      });
    }
  }, [formData.latitude, formData.longitude]);

  return (
    <div
      className={
        i18n.language != "ar"
          ? "addNewAddress"
          : "addNewAddress addNewAddress-ar"
      }
    >
      <div className="d-flex align-items-center add-shippingBox">
        <LeftOutlined onClick={handleStep(0)} />
        <h4>{t("AddShippingHeading")}</h4>
      </div>
      <h5>{t("CurrentLocationHeading")}</h5>
      <div className="address-map">
        <div id="map" style={{ width: "100%", height: "400px" }}></div>
        <Input
          value={searchAddress}
          onChange={handleSearchInputChange}
          placeholder="Search Address"
          className="custom-input"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSearchAddress();
            }
          }}
          onBlur={handleSearchAddress}
        />
      </div>
      <div className="profile-form">
        <Form form={form} onFinish={handleAddAddress}>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <label htmlFor="locationTitle">{t("LocationLabel")}</label>
              {/* <Form.Item
                    name="locationTitle"
                    rules={[{ min: 4, validator: validateTextField }]}
                    
                > */}
              <Input
                name="locationTitle"
                value={formData.locationTitle}
                onChange={handleInputChange}
                className="custom-input"
                disabled={true}
              />
              {/* </Form.Item> */}
            </div>
            <div className="col-md-6 col-sm-12">
              <label htmlFor="addressTitle">{t("AddressTitleLabel")}</label>
              <Form.Item
                name="addressTitle"
                rules={[{ min: 4, validator: validateTextField }]}
              >
                <Input
                  name="addressTitle"
                  value={formData.addressTitle}
                  onChange={handleInputChange}
                  className="custom-input"
                />
              </Form.Item>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <label htmlFor="addressLine1">{t("AddressLine1Label")}</label>
              <Form.Item
                name="addressLine1"
                rules={[{ min: 4, validator: validateTextField }]}
              >
                <Input
                  name="addressLine1"
                  value={formData.addressLine1}
                  onChange={handleInputChange}
                  className="custom-input"
                />
              </Form.Item>
            </div>
            <div className="col-md-6 col-sm-12">
              <label htmlFor="addressLine2">{t("AddressLine2Label")}</label>
              <Form.Item
                name="addressLine2"
                rules={[{ min: 4, validator: validateTextField }]}
              >
                <Input
                  name="addressLine2"
                  value={formData.addressLine2}
                  onChange={handleInputChange}
                  className="custom-input"
                />
              </Form.Item>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <label htmlFor="area">{t("AreaLabel")}</label>
              <Form.Item
                name="area"
                rules={[{ min: 4, validator: validateTextField }]}
              >
                <Input
                  name="area"
                  value={formData.area}
                  onChange={handleInputChange}
                  className="custom-input"
                />
              </Form.Item>
            </div>
            <div className="col-md-6 col-sm-12">
              <label htmlFor="emirate">{t("Emirate")}</label>
              <Form.Item
                name="emirate" // Provide a name attribute for the Form.Item
                rules={[
                  { required: true, message: "Please select an emirate" },
                ]} // Add validation rules
              >
                <Select
                  placeholder="Select Emirate"
                  onChange={handleEmirateChange}
                  value={selectedEmirate}
                  className="custom-select"
                >
                  <Option value="Dubai">Dubai</Option>
                  <Option value="Abu Dhabi">Abu Dhabi</Option>
                  <Option value="Sharjah">Sharjah</Option>
                  <Option value="Ajman">Ajman</Option>
                </Select>
              </Form.Item>
            </div>
          </div>
          <Button className="profile-btn" htmlType="submit">
            {t("AddButtonText")}
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default AddNewAddress;
