import React from "react";
import { Modal, Button } from "antd";
import OtpInput from "react-otp-input";
import "./OTPVerificationModal.css";

const OTPVerificationModal = ({
  openOtpModal,
  onClose,
  onResend,
  onSubmit,
  otp,
  setOtp,
  resendDisabled,
  secondsRemaining,
}) => {
  return (
    <Modal
      title="Otp Verification"
      open={openOtpModal}
      onCancel={onClose}
      className="otp-modal-s"
      centered
      footer={[
        <Button key="skip" onClick={onResend} disabled={resendDisabled}>
          Resend
        </Button>,
        <Button key="verify" onClick={onSubmit}>
          Verify
        </Button>,
      ]}
    >
      <div>
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          isInputNum
          renderInput={(props) => <input {...props} />}
          // inputStyle={{
          //   width: '40px',
          //   height: '40px',
          //   margin: '4px',
          //   fontSize: '24px',
          //   textAlign: 'center',
          //   borderRadius: '4px',
          //   border: '1px solid #d9d9d9',
          // }}
        />
        {resendDisabled ? `Resend OTP in ${secondsRemaining}s` : ""}
      </div>
    </Modal>
  );
};

export default OTPVerificationModal;
