import React, { useEffect } from "react";
import BreadCrumbs from "../../Components/BreadCrumbs/BreadCrumbs";
import "./Wishlist.css";
import WishlistTable from "../../Components/WishlistTable/WishlistTable";
import { useTranslation } from "react-i18next";

const Wishlist = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0,0)
  }, []);
  

  return (
    <div className="wishlist">
      <BreadCrumbs currentPage={t("BreadCrumpWishlist")} />
      <div className="container custom-container">
        <WishlistTable />
      </div>
    </div>
  );
};

export default Wishlist;