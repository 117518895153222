import React from "react";
import "./LoyaltyPoints.css";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import DashboardCard from "../DashboardCard/DashboardCard";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import PendingPoints from "../PendingPoints/PendingPoints";
import { getLoyaltyPoints } from "../../Services/Api/Api";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";


const LoyaltyPoints = ({userProfileData}) => {
   
  const { t, i18n } = useTranslation();
  const [pendingPoints,setPendingPoints] = useState([])
  const [userDetails,setUserdetails] = useState([])
  const [limit,setLimit] = useState(5)
  const[offset,setOffset] = useState(0)
  const [allDataLoaded, setAllDataLoaded] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const steps = [t('MyLoyaltyTabHead1'), t('MyLoyaltyTabHead2')];

  useEffect(() => {
    getData();
  }, [limit]);

  const getData = async () => {
    const result = await getLoyaltyPoints(offset, limit);
    const { data, status, error } = result || {};
    if (status === 200) {
        setPendingPoints(data.loyalty_points);
        setUserdetails(data.user_details);
        if (data && data.loyalty_points.length < limit) {
          setAllDataLoaded(true);
        }
     
    } else {
      console.log(error);
    }
  };
 
  const handleStep = (step) => () => {
    setActiveStep(step);
};

    const cardData = [
        { title: t('MyLoyaltySubHeading1'), value: userProfileData?.loyalty_points?.earned,
        // userProfileData?.loyalty_points?.earned,
         image: 'assets/images/cardstardollar.png' },
        { title: t('MyLoyaltySubHeading2'), value: 0, image: 'assets/images/cardstardollar.png' },
        { title: t('MyLoyaltySubHeading3'), value: userProfileData?.loyalty_points?.avaiable, image: 'assets/images/cardstardollar.png' }
    ];
    const renderStepContent = (step) => {
      switch (step) {
          case 0:
            return <PendingPoints pendingData={pendingPoints} setLimit={setLimit} allDataLoaded={allDataLoaded} />;
          case 1:
            return <PendingPoints pendingData={pendingPoints} setLimit={setLimit} loyaltyhistory={'loyaltyhistory'} allDataLoaded={allDataLoaded} />;
         
          default:
              return null;
      }
  };
  return (
    <div className="loyalty-points">
      <h4>{t("MyLoyaltyHeading")}</h4>
      <DashboardCard data={cardData} />

      <div className="d-flex pending-points ">
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <div
              className={`label ${activeStep === index ? "active" : ""}`}
              onClick={handleStep(index)}
            >
              {label}
            </div>
          </Step>
        ))}
      </div>
      <div>{renderStepContent(activeStep)}</div>
    </div>
  );
};

export default LoyaltyPoints;
