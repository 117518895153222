import React, { useState, useEffect } from "react";
import BreadCrumbs from "../../Components/BreadCrumbs/BreadCrumbs";
import "./ProductDetails.css";
import { Button, IconButton } from "@mui/material";
import { Rate, Tabs, notification } from "antd";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ImageGallery from "../../Components/ImageGallery/ImageGallery";
import {
  getProductsDetails,
  getProducts,
  getAllCategory,
  getProductReviews,
} from "../../Services/Api/Api";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import {
  selectToken,
  selectWishlistCount,
  selectProductData,
  selectCartCount,
} from "../../Services/Store/Store";
import {
  formatDate,
  handleAddDelToWishlist,
  handleProductsQuantity,
} from "../../Services/Utils/Utils";
import { useSelector, useDispatch } from "react-redux";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

const ProductDetails = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const wishlistCount = useSelector(selectWishlistCount);
  const cartCount = useSelector(selectCartCount);
  const productData = useSelector(selectProductData);
  const token = useSelector(selectToken);
  const [productDetails, setProductDetails] = useState([]);
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [baseURL, setBaseUrl] = useState("");
  const [category, setCategory] = useState("");
  const {
    description,
    recipes,
    nutrients,
    discounted_price,
    price,
    images,
    title,
    featured,
    promoted,
    tags,
    sku,
    stock,
    cart_details,
    favourite,
    category_id,
  } = productDetails || {};
  const [reviewsData, setReviewsData] = useState([]);

  const getCategory = async () => {
    const result = await getAllCategory();
    const { data, status, error } = result || {};
    if (status === 200) {
      const list = data?.categories;
      const item = list.find((item) => item?._id === category_id);
      setCategory(item?.name);
    } else {
      console.log(error);
    }
  };

  const getReviews = async (productId) => {
    const result = await getProductReviews(productId);
    const { data, status, error } = result || {};
    if (status == 200) {
      setReviewsData(data?.product_reviews);
    } else {
      console.log(error);
    }
  };

  useEffect(() => {
    getCategory();
  }, [category_id]);

  // Synchronizing Diff Components Based on Wishlist Add and Del
  useEffect(() => {
    if (productData) {
      setProductDetails((prev) =>
        prev?._id === productData?.p_id
          ? {
              ...prev,
              favourite: productData?.favourite,
              cart_details: { quantity: productData?.quantity },
            }
          : prev
      );
    }
  }, [productData]);

  // Synchronizing Diff Components Based on Featured Products Add and Sub
  useEffect(() => {
    if (productData) {
      setFeaturedProducts((prev) =>
        prev?.map((item) =>
          item?._id === productData?.p_id
            ? {
                ...item,
                cart_details: { ...item, quantity: productData?.quantity },
              }
            : item
        )
      );
    }
  }, [productData]);

  useEffect(() => {
    if (!id) {
      navigate("/");
      return;
    }
    getProductsData(id);
    getFeaturedData();
    getReviews(id);
    window.scrollTo(0, 0);
  }, [id]);

  // Get Products Details Data
  const getProductsData = async (id) => {
    const result = await getProductsDetails(id);
    const { data, status, error } = result || {};
    if (status === 200) {
      setProductDetails(data?.product_details[0]);
      setBaseUrl(data?.base_url);
    } else {
      console.log("Internal server error");
      navigate("/");
    }
  };

  // Get Featured Products Details Data
  const getFeaturedData = async () => {
    const filters = { featured_products: true };
    const result = await getProducts(filters, 4, 0);
    const { status, data, error } = result || {};
    if (status === 200) {
      // if (id && featuredProducts?.some(item => item?._id === id)) {
      setFeaturedProducts(data?.products?.filter((item) => item?._id !== id));
      // }
    } else {
      console.log("Internal server error");
    }
  };

  // Tabs
  const items = [
    {
      key: "1",
      label: t("DetailsTab1"),
      children: (
        <div
          dangerouslySetInnerHTML={{
            __html: i18n?.language === "en" ? description?.en : description?.ar,
          }}
        />
      ),
    },
    {
      key: "2",
      label: t("DetailsTab2"),
      children: (
        <div
          dangerouslySetInnerHTML={{
            __html: i18n?.language === "en" ? nutrients?.en : nutrients?.ar,
          }}
        />
      ),
    },
    {
      key: "3",
      label: t("DetailsTab3"),
      children: recipes?.map((recipe) => (
        <div
          dangerouslySetInnerHTML={{
            __html:
              i18n?.language === "en" ? recipe?.title?.en : recipe?.title?.ar,
          }}
        />
      )),
    },
    {
      key: "4",
      label: t("DetailsTab4"),
      children: (
        <>
          {reviewsData.length > 0 ? (
            <div className="reviews-wrapper">
              {reviewsData?.map((review, index) => (
                <div key={index} className="row custom-row">
                  <div className="left">
                    <div className="imageBox">
                      <img
                        src={
                          review.img
                            ? `${baseURL}${review.img}`
                            : "/assets/images/default_img.png"
                        }
                        alt="review"
                      />
                    </div>
                  </div>
                  <div className="right">
                    <div className="review-content">
                      <h5>{review?.name}</h5>
                      <h6>{formatDate(review?.t)}</h6>
                    </div>
                    <Rate disabled allowHalf defaultValue={review?.rating} />
                    <p className="review-para">{review.description}</p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            t("ProdDetNoReview")
          )}
        </>
      ),
    },
  ];

  return (
    <div
      className={
        i18n?.language === "en"
          ? "product-details"
          : "product-details product-details-ar"
      }
    >
      <BreadCrumbs
        category={{
          name: i18n?.language === "en" ? category?.en : category?.ar,
          link: "/products",
        }}
        currentPage={i18n?.language === "en" ? title?.en : title?.ar}
      />
      <div className="container custom-container">
        <div className="col-wrapper">
          <div className="col-left">
            <div className="top-wrapper">
              <div className="slider-box">
                <ImageGallery images={images} baseURL={baseURL}/>
              </div>

              <div className="infoBox">
                {featured && (
                  <img
                    src={
                      i18n?.language === "en"
                        ? "/assets/images/featured-image.png"
                        : " /assets/images/featured-image-ar.png"
                    }
                    alt="featured"
                  />
                )}
                <h3>{i18n?.language === "en" ? title?.en : title?.ar}</h3>
                <h4>
                  {price && discounted_price?.value > 0 ? (
                    i18n?.language === "en" ? (
                      <span>{price?.value + " " + price?.curr}</span>
                    ) : (
                      <span>{price?.curr + " " + price?.value}</span>
                    )
                  ) : i18n?.language === "en" ? (
                    <>{price?.value + " " + price?.curr}</>
                  ) : (
                    <>{price?.curr + " " + price?.value}</>
                  )}
                  {discounted_price?.value > 0 &&
                    (i18n?.language === "en"
                      ? discounted_price?.value + " " + discounted_price?.curr
                      : discounted_price?.curr + " " + discounted_price?.value
                    )}
                </h4>
                <p>
                  {t("ProdDetOnly")} {stock} {t("ProdDetRemain")}
                </p>

                <h5>
                  <span>{t("ProdDetSKU")}:</span> {sku}
                </h5>
                <h5>
                  <span>{t("ProdDetTags")}:</span>{" "}
                  {i18n?.language === "en"
                    ? tags?.en?.join(", ")
                    : tags?.ar?.join("، ")}
                </h5>
                <h5>
                  <span>{t("ProdDetCategory")}:</span>{" "}
                  {i18n?.language === "en" ? category?.en : category?.ar}
                </h5>

                <div className="quantityBox">
                  {token ? (
                    <>
                      <h5>{t("ProdDetQuantity")}:</h5>
                      <div className="input-group quantity">
                        {/* {cart_details?.quantity? ( */}
                        <div className="product-button-wrapper d-flex">
                          <IconButton
                            className="minus-button btn"
                            onClick={() => {
                              handleProductsQuantity(
                                productDetails,
                                "-",
                                setProductDetails,
                                dispatch,
                                token,
                                navigate,
                                cartCount
                              );
                            }}
                          >
                            <RemoveRoundedIcon
                              sx={{ color: "#EB1933", fontSize: 20 }}
                            />
                          </IconButton>

                          <div className="product-quantity form-control">
                            {cart_details?.quantity || 0}
                          </div>

                          <IconButton
                            className="add-button btn"
                            onClick={() => {
                              handleProductsQuantity(
                                productDetails,
                                "+",
                                setProductDetails,
                                dispatch,
                                token,
                                navigate,
                                cartCount
                              );
                            }}
                          >
                            <AddRoundedIcon
                              sx={{ color: "#EB1933", fontSize: 20 }}
                            />
                          </IconButton>
                        </div>
                        {/* ) : (
                        <div className="product-button-wrapper btn">
                          <IconButton className="add-button" onClick={() => { handleProductsQuantity(productDetails, "+", setProductDetails, dispatch, token, navigate, cartCount); }}>
                            <AddRoundedIcon sx={{ color: "#EB1933", fontSize: 20 }} />
                          </IconButton>
                        </div>
                      )} */}
                      </div>
                    </>
                  ) : (
                    <>
                      <br />
                      <br />
                    </>
                  )}
                </div>

                <div className="buttonBox">
                  <Button
                    className="wishlist"
                    style={{ background: favourite ? "#E0E0E0" : "" }}
                    onClick={() =>
                      handleAddDelToWishlist(
                        productDetails,
                        setProductDetails,
                        dispatch,
                        token,
                        navigate,
                        wishlistCount
                      )
                    }
                  >
                    <FavoriteIcon /> {t("AddToWishlistText")}
                  </Button>
                  {!token && (
                    <Button
                      className="cartBtn"
                      onClick={() => navigate("/login")}
                    >
                      <img src="/assets/images/details-cart.png" alt="heart" />{" "}
                      {t("AddToCartText")}
                    </Button>
                  )}
                </div>
              </div>
            </div>

            <div className="descriptionBox">
              <Tabs defaultActiveKey="1" items={items} />
            </div>
          </div>

          {/****** Featured Products ******/}
          <div className="col-right">
            <div className="featured-banner">
              <img
                src={
                  i18n?.language === "en"
                    ? "/assets/images/product-detail-banner.png"
                    : " /assets/images/product-detail-banner-ar.png"
                }
                alt="banner"
              />
            </div>
            {/* {featuredProducts?.length != 0 ? 
              <div className="featured-wrapper">
                <h3 className="heading">{t('FeatureProductHeading')}</h3>
                {featuredProducts?.map((itemData, index) => (
                  <div className="featured-item" key={index}>
                    <div className="image">
                      <img src={baseURL + itemData?.images[0]} alt="item" />
                    </div>
                    <div className="details">
                      <h4 className="product-name">{i18n?.language === "en" ? itemData?.title?.en :  itemData?.title?.ar} </h4>
                      <div className="price">
                        {itemData?.price?.value && (
                          <span className={itemData?.promoted ? "promoted" : ""}>
                            {itemData?.price?.value} {itemData?.price?.curr}
                          </span>
                        )}
                        
                        {itemData?.promoted && (
                          <span>
                            {itemData?.discounted_price?.value}
                            {itemData?.discounted_price?.curr}
                          </span>
                        )}
                      </div>

                      {itemData?.cart_details?.quantity ? (
                        <div className="product-button-wrapper cartQty-btn d-flex">
                          <IconButton className="minus-button" onClick={() => { handleProductsQuantity(itemData, "-", setFeaturedProducts, dispatch, token, navigate, cartCount); }} >
                            <RemoveRoundedIcon sx={{ color: "#EB1933", fontSize: 20 }} />
                          </IconButton>

                          <div className="product-quantity">{itemData?.cart_details?.quantity}</div>

                          <IconButton className="add-button" onClick={() => { handleProductsQuantity(itemData, "+", setFeaturedProducts, dispatch, token, navigate, cartCount); }}>
                            <AddRoundedIcon sx={{ color: "#EB1933", fontSize: 20 }} />
                          </IconButton>
                        </div>
                      ) : (
                        <div className="product-button-wrapper">
                          <Button className="add-button" onClick={() => { handleProductsQuantity(itemData, "+", setFeaturedProducts, dispatch, token, navigate, cartCount); }}
                            variant="contained"
                            startIcon={<img src="/assets/images/cart.png" alt="btn" /> }
                          >
                            Add to Cart
                          </Button>
                        </div>
                      )}

                    </div>
                  </div>
                ))}
              </div>
            : null
            } */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
