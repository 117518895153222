import React, { useState } from 'react';
import { Select, Input, Button, Modal, notification } from 'antd';
import '../CancelRequestModal/CancelRequestModal.css';
import { useTranslation } from 'react-i18next';
import { cancelOrder } from '../../Services/Api/Api';
import { errMess } from '../../Services/Utils/Utils';

function CancelRequestModal({ visible, setVisible, order_id }) {
  const { t, i18n } = useTranslation();
  const [selectedOption, setSelectedOption] = useState(null);
  const [enteredText, setEnteredText] = useState('');

  const onCancel = () => {
    setVisible(false);
  };
  const handleCancelRequest = async () => {
    if (selectedOption != null && enteredText != "") {
      const result = await cancelOrder(order_id, selectedOption, enteredText);
      const { status, data, error } = result || {};
      if (status === 200) {
        notification.success({ message: "Order has been Cancelled" });
        setVisible(false);
        window.location.reload();
      } else {
        notification.error({ message: errMess(error) });
      }
    }
  };

  return (
    <Modal
      title={t('CancelRequestHeading')}
      open={visible}
      onCancel={onCancel}
      centered
      className="cancel-modal-s"
      footer={[
        <Button key="verify" onClick={handleCancelRequest}>
          {t('CancelRequestSubmit')}
        </Button>,
      ]}
    >
      <Select
        placeholder={t('CancelRequestSelectPH')}
        options={[
          {
            value: 'mistake',
            label: t('CancelRequestSelectOption1'),
          },
          {
            value: 'changemind',
            label: t('CancelRequestSelectOption2'),
          },
          {
            value: 'edit',
            label: t('CancelRequestSelectOption3'),
          },
          {
            value: 'others',
            label: t('CancelRequestSelectOption4'),
          },
        ]}
        onChange={value => setSelectedOption(value)} // Update selected option state
      />
      <Input.TextArea
        rows={5}
        placeholder={t('CancelRequestTextareaPH')}
        maxLength={150}
        value={enteredText}
        onChange={e => setEnteredText(e.target.value)} // Update entered text state
      />
    </Modal>
  );
}

export default CancelRequestModal;
