import React from "react";
import "./Signup.css";
import { Form, Input, Button, notification } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import BreadCrumbs from "../../../Components/BreadCrumbs/BreadCrumbs";
import { useNavigate } from "react-router-dom";
import { setToken } from "../../../Services/Store/Store";
import { useDispatch } from "react-redux";
import { registerUser } from "../../../Services/Api/Api";
import { validateConfirmPassword, validateTextField, errMess, } from "../../../Services/Utils/Utils";
import { useTranslation } from "react-i18next";

const Signup = () => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Handle SignUp
  const onFinish = async (values) => {
    const result = await registerUser(values);
    const { status, data, error } = result || {};
    if (status === 200) {
      const { access_token, refresh_token } = data?.token || {};
      localStorage.setItem("token", access_token);
      localStorage.setItem("refresh_token", refresh_token);
      localStorage.setItem("data", JSON.stringify(data));
      dispatch(setToken(access_token));
      navigate("/", { state: { userData: "SignUp" } });
    } else {
      notification.error({ message: errMess(error) });
    }
  };

  const handleLoginClick = () => {
    navigate("/login");
  };

  return (
    <div>
      <BreadCrumbs currentPage={t("BreadCrumpSignup")} />
      <div className="signup">
        <Form className="signup-form" form={form} onFinish={onFinish}>
          <div className="signup-heading">
            <h3>{t("SignUpHeading")}</h3>
          </div>

          <div className="input-fields-signup">
            <Form.Item
              name="first_name"
              rules={[{ min: 4, validator: validateTextField }]}
            >
              <Input
                placeholder={t("SignUpFirstNamePlaceholder")}
                prefix={<img src={"assets/images/name.png"} alt="Name" />}
              />
            </Form.Item>
            <Form.Item
              name="last_name"
              rules={[{ min: 4, validator: validateTextField }]}
            >
              <Input
                placeholder={t("SignUpLastNamePlaceholder")}
                prefix={<img src={"assets/images/name.png"} alt="Name" />}
              />
            </Form.Item>
          </div>

          <div>
            <Form.Item
              name="email"
              rules={[{ type: "email", validator: validateTextField }]}
            >
              <Input
                placeholder={t("SignUpEmailPlaceholder")}
                prefix={<img src={"assets/images/email.png"} alt="Email" />}
              />
            </Form.Item>
            {/* <Input 
              placeholder="Mobile Number"  
              name="mobileNumber"
              value={formData.mobileNumber} 
              prefix={<img src={'assets/images/phone.png'} alt="Phone" />}  
              onChange={handleInputChange} 
            /> */}
          </div>

          <div className="input-fields-signup">
            <Form.Item
              name="password"
              rules={[{ min: 8, validator: validateTextField }]}
            >
              <Input.Password
                placeholder={t("SignUpPasswordPlaceholder")}
                type="password"
                prefix={
                  <img src={"assets/images/passwordIcon.png"} alt="Password" />
                }
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
            <Form.Item
              name="confirm_password"
              rules={[
                {
                  validator: validateConfirmPassword(form),
                },
              ]}
            >
              <Input.Password
                placeholder={t("SignUpConfirmPasswordPlaceholder")}
                type="password"
                prefix={
                  <img src={"assets/images/passwordIcon.png"} alt="Password" />
                }
              />
            </Form.Item>
          </div>

          <div className="signup-btn">
            <Button htmlType="submit">{t("SignUpHeading")}</Button>
          </div>

          <div className="dont-have-accont-signup">
            <p>
              {t("SignUpAccountText")}{" "}
              <span className="sign-up" onClick={handleLoginClick}>
                {t("SignUpLoginText")}
              </span>
            </p>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Signup;