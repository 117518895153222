import React, { useEffect, useState } from "react";
import "./Profile.css";
import { Button, Input, Form, notification, message } from "antd";
import { getUserProfile, otpVerify, passwordResetProfile, phoneUser, updateUserProfile, } from "../../Services/Api/Api";
import { validateConfirmPassword, validateTextField, } from "../../Services/Utils/Utils";
import PhoneVerificationModal from "../PhoneVerificationModal/PhoneVerificationModal";
import OTPVerificationModal from "../OTPVerificationModal/OTPVerificationModal";
import { useTranslation } from "react-i18next";

const Profile = ({ userProfileData, fullName, setFullName, getData }) => {
  const [form] = Form.useForm();
  const [formPassword] = Form.useForm();
  const { t, i18n } = useTranslation();
  const [otp, setOtp] = useState("");
  const [showPhoneVerificationModal, setShowPhoneVerificationModal] = useState(false);
  const [openOtpModal, setOpenOtpModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [resendDisabled, setResendDisabled] = useState(true);
  const [secondsRemaining, setSecondsRemaining] = useState(40);

  const handlePhoneVerificationSubmit = async () => {
    const result = await phoneUser(phoneNumber);
    const { status, error, data } = result || {};
    if (status === 200) {
      setOpenOtpModal(true);
      notification.success({ message: data?.message });
    } else {
      notification.error({ message: "Error" });
      console.log(error);
      message.error(error.en ? error.en : error?.moreInfo);
      // setOpenOtpModal(true);
    }
  };

  const handleOTPVerificationSubmit = async () => {
    const result = await otpVerify(otp, phoneNumber);
    const { status, error } = result || {};
    if (status === 200) {
      setOpenOtpModal(false);
      setShowPhoneVerificationModal(false);
      getData();
      notification.success({ message: "Phone has been verified" });
    } else {
      notification.error({ message: error });
      console.log(error);
      message.error(error);
      // setOpenOtpModal(true);
    }
  };

  const handlePhoneVerificationSkip = () => {
    setShowPhoneVerificationModal(false);
  };

  const handlePhoneNumberChange = (event) => {
    const { value } = event.target;
    setPhoneNumber(value);
  };

  const handleResendClick = () => {
    setOtp("");
    setResendDisabled(true);
    setSecondsRemaining(30);
    handlePhoneVerificationSubmit();
  };

  const handleSaveChanges = async () => {
    const result = await updateUserProfile(fullName);
    const { status, data, error } = result || {};
    if (status === 200) {
      notification.success({ message: "User Name Updated Successfully" });
    } else {
      notification.error({ message: error });
      console.log(error);
    }
    console.log("Full Name:", fullName);
  };

  const handleUpdatePassword = async (values) => {
    const result = await passwordResetProfile(values);
    const { status, data, error } = result || {};
    if (status === 200) {
      notification.success({ message: data?.message });
      formPassword.resetFields();
    } else {
      notification.error({ message: error });
      console.log(error);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setResendDisabled(false); // Enable resend button after 30 seconds
    }, 30000);
    const interval = setInterval(() => {
      setSecondsRemaining((prevSeconds) => prevSeconds - 1); // Update countdown timer every second
    }, 1000);
    return () => {
      clearTimeout(timer); // Clear the timer on component unmount
      clearInterval(interval); // Clear the interval on component unmount
    };
  }, []); // Run this effect only once on component mount

  useEffect(() => {
    if (secondsRemaining === 0) {
      setResendDisabled(false); // Enable resend button when timer reaches 0
    }
  }, [secondsRemaining]); // Update when secondsRemaining changes

  return (
    <div className="profile">

      <div className="profile-form">
        <h4 className="heading">{t("MyProfileHeading")}</h4>
        <Form form={form} onFinish={handleSaveChanges} >
          <div className="row">
            <div className="col-12">
              <label htmlFor="fullName">{t("MyProfileFullName")}</label>
            
               <Form.Item
                  name="first_name"
                  rules={[{ min: 4, validator: validateTextField }] }
                  initialValue={fullName}
                > 
                <Input
                name="fullName"
                
                className="custom-input"
                // onClick={handleFullNameClick} // Handle click on Full Name field
                onChange={(e) => setFullName(e.target.value)} // Update fullName state when input changes
              />
                  </Form.Item>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <label htmlFor="email">{t("MyProfileEmail")}</label>
              <Input
                name="email"
                value={userProfileData?.email || ""}
                className="custom-input"
                disabled={true}
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <label htmlFor="phone"> {t("MyProfilePhone")}</label>
              <Input
                placeholder={t("MyProfilePhonePlaceholder")}
                name="phone"
                value={userProfileData?.phone || ""}
                className="custom-input"
                onClick={() => setShowPhoneVerificationModal(true)} // Open modal on click
                disabled={userProfileData.phone ? true : false}
                readOnly // Make the input read-only
              />
            </div>
          </div>
          <Button className="profile-btn" htmlType="submit">
            {t("MyProfileSaveButton")}
          </Button>
        </Form>
      </div>

      <div className="profile-form">
        <h4 className="heading">{t("MyProfilePasswordHead")}</h4>
        <Form form={formPassword} onFinish={handleUpdatePassword}>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <label htmlFor="currentPassword">
                {t("MyProfileCurrentPassword")}
              </label>
              <Form.Item
                name="current_password"
                rules={[{ validator: validateTextField }]}
              >
                <Input.Password
                  type="password"
                  className="custom-input"
                />
              </Form.Item>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <label htmlFor="newPassword">{t("MyProfileNewPassword")}</label>
              <Form.Item
                name="password"
                rules={[{ min: 8, validator: validateTextField }]}
              >
                <Input.Password
                  type="password"
                  className="custom-input"
                />
              </Form.Item>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <label htmlFor="confirmNewPassword">
                {t("MyProfileConfirmNewPassword")}
              </label>
              <Form.Item
                name="confirm_password"
                rules={[
                  {
                    validator: validateConfirmPassword(formPassword),
                  },
                ]}
              >
                <Input.Password
                  type="password"
                  className="custom-input"
                />
              </Form.Item>
            </div>
          </div>
          <Button className="profile-btn" htmlType="submit">
            {t("MyProfileUpdatePassword")}
          </Button>
        </Form>
      </div>

      <PhoneVerificationModal
        visible={showPhoneVerificationModal}
        onCancel={() => setShowPhoneVerificationModal(false)}
        onSkip={handlePhoneVerificationSkip}
        onSubmit={handlePhoneVerificationSubmit}
        phoneNumber={phoneNumber}
        onChange={handlePhoneNumberChange}
        openOtpModal={openOtpModal}
      />

      <OTPVerificationModal
        openOtpModal={openOtpModal}
        onClose={() => setOpenOtpModal(false)}
        onResend={handleResendClick}
        onSubmit={handleOTPVerificationSubmit}
        otp={otp}
        setOtp={setOtp}
        resendDisabled={resendDisabled}
        secondsRemaining={secondsRemaining}
      />

    </div>
  );
};

export default Profile;
